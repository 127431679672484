/// <reference path="../pages/configuration/contentdetail.js" />
import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl, AccessRight, LoginType } from "../util/Constant";

//Login
import Login from './../pages/home/Login';

import FAQHelp from './../pages/home/FAQHelp';

import Dashboard from './../pages/home/Dashboard.js';
import SuperAccount from './../pages/admin/SuperAccount.js';
import Company from './../pages/admin/ManageCompany.js';
import MemberDetail from './../pages/account/MemberDetail.js';
import ManageMember from './../pages/account/ManageMember.js';
import ManageMember2 from './../pages/account/ManageMember2.js';
import ManageMemberPO from './../pages/account/ManageMemberPO';
import ManageMemberCS from './../pages/account/ManageMemberCS';

import ChatRoom from './../pages/chat/chatroom.js';
import ChatTemplate from './../pages/chat/template.js';

import LiveMonitor from './../pages/home/LiveMonitor.js';
import RewardsSetting from './../pages/rewards/RewardsSetting.js';
import RewardsSchedule from './../pages/rewards/RewardsSchedule.js';
import ClaimedRewardsReport from './../pages/rewards/ClaimedRewardsReport';
import ForfeitedRewardsReport from './../pages/rewards/ForfeitedRewardsReport';
import SportsLiveMonitor from './../pages/home/SportsLiveMonitor.js';
import SportsTeamBetReport from './../pages/home/SportsTeamBetReport';

import ManageMemberProductUsername from './../pages/account/ManageMemberProductUsername.js';
import ManageMemberFullname from './../pages/report/ManageMemberFullname.js';

import WalletBalance from './../pages/account/WalletBalance.js';
import RollOverStatement from './../pages/account/RollOverStatement.js';
import ShareholdersList from './../pages/account/ShareholdersList.js';
import ShareholdersViewAccount from './../pages/account/ShareholdersViewAccount.js';
import ShareholdersProductList from './../pages/account/ShareholdersProductList.js';
import ShareholdersViewProduct from './../pages/account/ShareholdersViewProduct.js';
import ShareholdersViewPayout from './../pages/account/ShareholdersViewPayout.js';
import ShareholdersHuatTicket from './../pages/account/ShareholdersHuatTicket.js';
import ShareholdersViewHuatTicket from './../pages/account/ShareholdersViewHuatTicket.js';
import ShareholdersProcessSales from './../pages/account/ShareholdersProcessSales.js';
import TodayNewMembers from './../pages/account/TodayNewMembers.js';
import TransferLog from './../pages/account/TransferLog';

import MemberProductMaintenance from './../pages/account/MemberProductMaintenance.js';
import CompanyDetail from './../pages/admin/CompanyDetail.js';
import ManageRole from './../pages/admin/ManageRole.js';
import RoleDetail from './../pages/admin/RoleDetail.js';
import ChangePassword from './../pages/admin/ChangePassword.js';
import ChangePinNumber from './../pages/admin/ChangePinNumber.js';
import ManageSubAccount from './../pages/admin/ManageSubAccount.js';
import SubAccountDetail from './../pages/admin/SubAccountDetail.js';
import CompanyProductMaintenance from './../pages/admin/CompanyProductMaintenance';
import CompanyProductApiInfo from './../pages/admin/CompanyProductApiInfo';
import CompanyProductAccountInfo from './../pages/admin/CompanyProductAccountInfo';
import ActivityLog from './../pages/admin/ManageActivityLog';
import ViewAccount from './../pages/account/ViewAccount.js';
import ViewProductAccount from './../pages/account/ViewProductAccount.js';
import ManageIpBlock from './../pages/account/ManageIpBlock';
import TransactionQueue from './../pages/account/TransactionQueue.js';
import ManageMobileTAC from './../pages/account/ManageMobileTAC.js';
import ManageBirthdayRewards from './../pages/account/ManageMemberBirthdayReward.js';
import ManageMemberWeeklyRescue from '../pages/account/ManageMemberWeeklyRescue.js';


import ManageMemberRebate from './../pages/account/ManageMemberRebate.js';
import ClaimedPromotionDetails from './../pages/account/ClaimedPromotionDetail';
//Product
import MasterProductDetail from './../pages/product/MasterProductDetail';
import ManageMasterProduct from './../pages//product/ManageMasterProduct.js';
import ProductMaintenance from './../pages/product/ProductMaintenance';
import ProductCategoryDetail from './../pages/product/ProductCategoryDetail';
import ManageProductCategory from './../pages/product/ManageProductCategory';
import AccountProductGameDetail from './../pages/product/AccountProductGameDetail';
import ManageAccountProductGame from './../pages/product/ManageAccountProductGame';
import Manage568WinBetLimit from './../pages/product/Manage568WinBetLimit';

import RetrieveMemberBetData from './../pages/product/RetrieveMemberBetData';

//Bank
import ManageBank from './../pages/configuration/bank/ManageBank.js';
import BankDetail from './../pages/configuration/bank/BankDetail.js';

//Bank Account
import ManageBankAccount from './../pages/configuration/bank/ManageBankAccount.js';
import BankAccountDetail from './../pages/configuration/bank/BankAccountDetail.js';

//Product Setting
import ManageProductSetting from './../pages/product/ManageProductSetting.js';
import ProductSettingDetail from './../pages/product/ProductSettingDetail.js';

//language
import ManageLanguage from './../pages/configuration/ManageLanguage.js';
import LanguageDetail from './../pages/configuration/LanguageDetail.js';

//banking channel
import ManageBankingChannel from './../pages/configuration/bank/ManageBankingChannel.js';
import BankingChannelDetail from './../pages/configuration/bank/BankingChannelDetail.js';

//Bonus Type
import ManageBonusType from './../pages/configuration/bonus/ManageBonusType.js';
import BonusTypeDetail from './../pages/configuration/bonus/BonusTypeDetail.js';

//Configuration
import ManageInstantPaySetting from './../pages/configuration/ManageInstantPaySetting'
import InstantPaySettingDetail from './../pages/configuration/InstantPaySettingDetail'
import RejectReasonDetail from './../pages/configuration/RejectReasonDetail'
import ManageRejectReason from './../pages/configuration/ManageRejectReason'
import SmsContentDetail from './../pages/configuration/sms/SmsContentDetail'
import ManageSmsContent from './../pages/configuration/sms/ManageSmsContent'
import SmsSettings from './../pages/configuration/sms/SmsSettings'
import ManageAnnouncement from './../pages/configuration/ManageAnnouncement'
import AnnouncementDetail from './../pages/configuration/AnnouncementDetail'
import ManageBanner from './../pages/configuration/ManageBanner'
import BannerDetail from './../pages/configuration/BannerDetail'
import ManageRewardBanner from './../pages/configuration/ManageRewardBanner'
import RewardBannerDetail from './../pages/configuration/RewardBannerDetail'
import ManagePromotion from './../pages/configuration/ManagePromotion'
import PromotionDetail from './../pages/configuration/PromotionDetail'
import ManageScript from './../pages/configuration/ManageScript'
import ScriptDetail from './../pages/configuration/ScriptDetail'
import ManageEmail from './../pages/configuration/ManageEmail'
import EmailDetail from './../pages/configuration/EmailDetail'
import ManageContent from './../pages/configuration/ManageContent'
import ContentDetail from './../pages/configuration/ContentDetail'
import VipLevel from './../pages/configuration/ManageVipLevel'
import VipLevelDetail from './../pages/configuration/VipLevelDetail'
import Remark from './../pages/configuration/ManageRemark'
import RemarkDetail from './../pages/configuration/RemarkDetail'
import Tag from './../pages/configuration/ManageTag'
import TagDetail from './../pages/configuration/TagDetail'
import BankPackage from './../pages/configuration/bank/ManageBankPackage'
import BankPackageDetail from './../pages/configuration/bank/BankPackageDetail'
import DefaultBetSetting from './../pages/defaultBetSetting/ManageBetSetting'
import ManageContactUs from './../pages/configuration/ManageContactUs'
import ContactUsDetail from './../pages/configuration/ContactUsDetail'
import ManageVipBenefit from './../pages/configuration/ManageVipBenefit'
import VipBenefitDetail from './../pages/configuration/VipBenefitDetail'
import ManageVipBenefitContent from './../pages/configuration/ManageVipBenefitContent'
import VipBenefitContentDetail from './../pages/configuration/VipBenefitContentDetail'
import ManageCommunication from './../pages/configuration/ManageCommunicationChannels'
import CommunicationChannelDetail from './../pages/configuration/CommunicationChannelDetail'
import ManageSystemSetting from '../pages/configuration/ManageSystemSetting'
import ManageRecurringJob from '../pages/configuration/ManageRecurringJob'
import ManageManualJob from '../pages/configuration/ManageManualJob'
import WebContent from '../pages/configuration/WebContent'
import ManageWebContent from '../pages/configuration/ManageWebContent'
import ManageDepositPromotion from '../pages/configuration/ManageDepositPromotion'
import DepositPromotionDetail from '../pages/configuration/DepositPromotionDetail'
import MarqueeTagContent from '../pages/configuration/ManageMarqueeTagContent'

import ManageSkinSettings from './../pages/configuration/ManageSkinSettings'
import SkinSettingsDetail from './../pages/configuration/SkinSettingsDetail'

import ManageGame from './../pages/product/ManageGame.js'
import GameDetail from './../pages/product/GameDetail.js'

import ManageWeeklyRescue from '../pages/configuration/ManageWeeklyRescue.js'
import WeeklyRescueDetail from '../pages/configuration/WeeklyRescueDetail.js'

//bonus
import ManageBonus from './../pages/configuration/bonus/ManageBonus'
import BonusDetail from './../pages/configuration/bonus/BonusDetail'

//Transaction
import Withdrawal from './../pages/transaction/Withdrawal.js';
import Deposit from './../pages/transaction/Deposit.js';
import Adjustment from './../pages/transaction/Adjustment.js';
import Bonus from './../pages/transaction/Bonus.js';
import TransactionEnquiry from './../pages/transaction/TransactionEnquiry.js';
import InstantTransaction from './../pages/transaction/InstantTransaction.js';
import TransactionDetail from './../pages/transaction/TransactionDetail.js';
import CommissionAdjustment from './../pages/adjustment/CommissionAdjustment.js';
import BonusAdjustment from './../pages/adjustment/BonusAdjustment.js';
import TnGSettings from './../pages/transaction/TnGSettings.js';
import WithdrawalSettings from './../pages/transaction/WithdrawalSettings.js';
import AdjustmentBonusWallet from './../pages/transaction/AdjustmentBonusWallet.js';

//Report
import ProfitLossReport from './../pages/report/ManageProfitLossReport.js';
import BetDetailReport from './../pages/report/ManageBetDetailReport.js';
import DailySummaryReport from './../pages/report/ManageDailySummaryReport.js';
import ChannelSummaryReport from './../pages/report/ManageChannelSummaryReport.js';
import BankSummaryReport from './../pages/report/ManageBankSummaryReport.js';
import MemberReport from './../pages/report/ManageMemberReport.js';
import ManageWinLossReport from './../pages/report/ManageWinLossReport';
import ManageTallyWinLossReport from './../pages/report/ManageTallyWinLossReport';
import ManageWinLossReport2 from './../pages/report/ManageWinLossReport2';
import ManageWinLossReport3 from './../pages/report/ManageWinLossReport3';
import ManageBonusCommReport from './../pages/report/ManageBonusCommReport';
import ManageWalletTransferReport from './../pages/report/ManageWalletTransfer';
import ServiceChargeReport from './../pages/report/ServiceChargeReport';
import TransactionReport from './../pages/report/TransactionReport';
import WalletTransferReport from './../pages/report/WalletTransferReport';
import AdjustmentReport from './../pages/report/AdjustmentReport';

import ShareholderProcessSalesReport from './../pages/report/ShareholderProcessSalesReport';
import ShareholderDownlineReport from './../pages/report/ShareholderDownlineReport';

import ProcessGameRecordReport from './../pages/report/ProcessGameRecordReport';
import NonProcessGameRecordReport from './../pages/report/NonProcessGameRecordReport';

import ShareholderUnclaimReport from './../pages/report/ShareholderUnclaimReport';
import ShareholderUnclaimReportList from './../pages/report/ShareholderUnclaimReportList';
import CommissionReportList from './../pages/report/CommissionReportList';

import TotalSharePayoutReport from './../pages/report/TotalSharePayoutReport';

import UsernameBetDetailReport from './../pages/report/UsernameBetDetailReport';
import TransactionSummaryReport from './../pages/report/TransactionSummaryReport';
import BirthdayRewardReport from './../pages/report/BirthdayRewardReport';
import GiftpaceReport from './../pages/report/GiftpaceReport';
import ActiveMemberReport from './../pages/report/ActiveMemberReport';

//Tool
import ManageIPMonitor from './../pages/ipMonitor/ManageIPMonitor';

import Maintenance from './../pages/home/Maintenance';
import ManageWalletHistory from '../pages/account/ManageWalletHistory';


import ManageVideoTutorial from './../pages/configuration/ManageVideoTutorial';
import VideoTutorialDetail from './../pages/configuration/VideoTutorialDetail';

import ManageWalletRecon from '../pages/account/ManageWalletRecon';
import DepositReference from '../pages/transaction/DepositReference';
import MarqueeTagContentDetail from '../pages/configuration/MarqueeTagContentDeatail';

// Daily Check In
import ManageDCItemList from '../pages/dc/ManageDCItemList';
import ManageDCRedemption from '../pages/dc/ManageDCRedemption';
import DCItemDetail from '../pages/dc/DCItemDetail';
import ManageDCPoints from '../pages/dc/ManageDCPoints';
import DCPointDetails from '../pages/dc/DCPointDetail';
import ManageMemberDCPoints from '../pages/dc/ManageMemberDCPoints';
import MemberDCPointsDetail from '../pages/dc/MemberDCPointsDetail';
import ManageDailyCheckIn from '../pages/dc/ManageDailyCheckIn.js'
import DailyCheckInDetail from '../pages/dc/DailyCheckInDetail.js'
import ManageMemberGamePoints from '../pages/account/ManageMemberGamePoints';
import PointsAdjustment from './../pages/adjustment/PointsAdjustment.js';
import PointsAdjustmentReport from './../pages/report/PointsAdjustmentReport.js';
import MemberPointsReport from './../pages/report/MemberPointsReport.js';

//Deposit Promotion Category
import ManageDepositPromotionCategory from '../pages/configuration/ManageDepositPromotionCategory'
import DepositPromotionCategoryDetail from '../pages/configuration/DepositPromotionCategoryDetail'
import CreditRedemptionReport from '../pages/report/CreditRedemptionReport.js';
import ManageBonusDeposit from '../pages/transaction/ManageBonusDeposit';
import BonusDepositDetail from '../pages/transaction/BonusDepositDetail';
import PlayerReconciliation from '../pages/home/PlayerReconciliation';

//Chat Sphere
import ManageChatSphere from '../pages/configuration/ManageChatSphere';
import ManageChatSphereType from '../pages/configuration/ManageChatSphereType';
import ChatSphereTypeDetail from '../pages/configuration/ChatSphereTypeDetail';
import ChatSphereContentDetail from '../pages/configuration/ChatSphereDetail';
import ManageChatSpherePhoneNumber from '../pages/configuration/ManageChatSpherePhoneNumber';
import ChatSpherePhoneNumberDetail from '../pages/configuration/ChatSpherePhoneNumberDetail';

import ManageMemberBankAccount from './../pages/account/ManageMemberBankAccount.js';
import MemberBankAccountDetail from './../pages/account/MemberBankAccountDetail.js';

import ManageProductRanking from './../pages/product/CustomProductRanking.js';
import CustomGamesRanking from './../pages/product/CustomGamesRanking.js';
import GameList from './../pages/product/GameList.js';

import ManageMLMCommission from '../pages/account/ManageMLMCommission';

import ManageChatSystemTemplate from './../pages/chat/ManageChatSystemTemplate.js';
import CustomChatTemplate from './../pages/chat/CustomChatTemplate.js';
import ManageCustomChatTemplate from './../pages/chat/ManageCustomChatTemplate.js';

/// <summary>
/// Author : -
/// </summary>
export const loginRoute =
{
    path: '/',
    exact: false,
    authRequired: false,
    component: () => <Redirect to='/login' />
}

/// <summary>
/// Author : -
/// </summary>
export const routes = [
    {
        path: WebUrl._URL_LOGIN,
        exact: true,
        title: 'LOGIN',
        authRequired: false,
        component: () => <Login />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <Redirect to={WebUrl._URL_DASHBOARD} />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        loginType: [LoginType._LOGIN_TYPE_MEMBER],
        component: () => <Redirect to={WebUrl._URL_WIN_LOSS_REPORT} />
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        title: 'DASHBOARD',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <Dashboard />
    },
    {
        path: WebUrl._URL_SUPER_ACCOUNT,
        title: 'SUPER_ACCOUNT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SUPER_COMPANY_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SuperAccount />
    },
    {
        path: WebUrl._URL_COMPANY_PROFILE,
        title: 'PROFILE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <CompanyDetail viewOwnProfile={true} />
    },
    {
        path: WebUrl._URL_MANAGE_COMPANY,
        title: 'COMPANIES',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._COMPANY_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <Company />
    },
    {
        path: WebUrl._URL_CREATE_OR_EDIT_COMPANY,
        title: 'COMPANY_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._COMPANY_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <CompanyDetail viewOwnProfile={false} />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_MEMBER,
        title: 'MEMBER_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <MemberDetail />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER,
        title: 'MANAGE_MEMBER',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMember />
    },
    {
        path: WebUrl._URL_CHAT_ROOM,
        title: 'CHATROOM',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LIVE_CHAT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ChatRoom />
    },
    {
        path: WebUrl._URL_CHAT_SYSTEM_TEMPLATE,
        title: 'CHATTEMPLATE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ChatTemplate />
    },
    {
        path: WebUrl._URL_MEMBER_PRORUCT_MAINTENANCE,
        title: 'MANAGE_MEMBER_PRODUCT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <MemberProductMaintenance />
    },
    {
        path: WebUrl._URL_MANAGE_BANK,
        title: 'MANAGE_BANK',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBank />
    },
    {
        path: WebUrl._URL_CREATE_OR_EDIT_BANK,
        title: 'BANK_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BankDetail />
    },
    {
        path: WebUrl._URL_LANGUAGE,
        title: 'MANAGE_LANGUAGE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LANGUAGE_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageLanguage />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_LANGUAGE,
        title: 'LANGUAGE_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LANGUAGE_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <LanguageDetail />
    },
    {
        path: WebUrl._URL_BANKING_CHANNEL,
        title: 'MANAGE_BANKING_CHANNEL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANKING_CHANNEL_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBankingChannel />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BANKING_CHANNEL,
        title: 'BANKING_CHANNEL_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANKING_CHANNEL_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BankingChannelDetail />
    },
    {
        path: WebUrl._URL_BONUS_TYPE,
        title: 'MANAGE_BONUS_TYPE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_TYPE_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBonusType />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BONUS_TYPE,
        title: 'BONUS_TYPE_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_TYPE_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BonusTypeDetail />
    },
    {
        path: WebUrl._URL_BANK_ACCOUNT,
        title: 'MANAGE_BANK_ACCOUNT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_ACCOUNT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBankAccount />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BANK_ACCOUNT,
        title: 'BANK_ACCOUNT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_ACCOUNT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BankAccountDetail />
    },
    {
        path: WebUrl._URL_MANAGE_ROLE,
        title: 'MANAGE_ROLE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageRole />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_ROLE,
        title: 'ROLE_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <RoleDetail />
    },
    {
        path: WebUrl._URL_MANAGE_SUB_ACCOUNT,
        title: 'MANAGE_SUB_ACCOUNT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSubAccount />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_SUB_ACCOUNT,
        title: 'SUB_ACCOUNT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <SubAccountDetail />
    },
    {
        path: WebUrl._URL_CHANGE_PASSWORD,
        title: 'CHANGE_PASSWORD',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <ChangePassword />
    },
    {
      path: WebUrl._URL_CHANGE_PIN_NUMBER,
      title: 'CHANGE_PIN_NUMBER',
      authRequired: true,
      loginType: [LoginType._LOGIN_TYPE_USER],
      component: () => <ChangePinNumber />
    },
    {
        path: WebUrl._URL_MASTER_PRODUCT,
        title: 'MASTER_PRODUCTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMasterProduct />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_MASTER_PRODUCT,
        title: 'MASTER_PRODUCT_DETAIL',
        permission: AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <MasterProductDetail />
    },
    {
        path: WebUrl._URL_PRODUCT_GAME,
        title: 'PRODUCTS_GAME',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageGame />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME,
        title: 'PRODUCTS_GAME_DETAIL',
        permission: AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <GameDetail />
    },
    {
        path: WebUrl._URL_INSTANT_PAY_SETTING,
        title: 'MANAGE_INSTANT_PAY_SETTINGS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._INSTANT_PAY_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageInstantPaySetting />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_INSTANT_PAY_SETTING,
        title: 'INSTANT_PAY_SETTINGS_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._INSTANT_PAY_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <InstantPaySettingDetail />
    },
    {
        path: WebUrl._URL_WITHDRAWAL,
        title: 'WITHDRAWAL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WITHDRAWAL_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <Withdrawal />
    },
    {
        path: WebUrl._URL_DEPOSIT,
        title: 'DEPOSIT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <Deposit />
    },
    {
        path: WebUrl._URL_ADJUSTMENT,
        title: 'ADJUSTMENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <Adjustment />
    },
    {
        path: WebUrl._URL_BONUS,
        title: 'BONUS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <Bonus />
    },
    {
        path: WebUrl._URL_TRANSACTION_ENQUIRY,
        title: 'TRANSACTION_ENQUIRY',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSACTION_ENQUIRY_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TransactionEnquiry />
    },
    {
        path: WebUrl._URL_INSTANT_TRANSFER,
        title: 'INSTANT_TRANSACTION',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._INSTANT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <InstantTransaction />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_REJECT_REASON,
        title: 'MANAGE_REJECT_REASON',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REJECT_REASON_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <RejectReasonDetail />
    },
    {
        path: WebUrl._URL_MANAGE_REJECT_REASON,
        title: 'MANAGE_REJECT_REASON',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REJECT_REASON_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageRejectReason />
    },
    {
        path: WebUrl._URL_MANAGE_SMS_CONTENT,
        title: 'MANAGE_SMS_CONTENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SMS_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSmsContent />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_SMS_CONTENT,
        title: 'SMS_CONTENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SMS_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SmsContentDetail />
    },
    {
        path: WebUrl._URL_SMS_SETTING,
        title: 'SMS_SETTINGS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SMS_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SmsSettings />
    },
    {
        path: WebUrl._URL_WEB_CONTENT,
        title: 'WEB_CONTENTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WEB_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <WebContent />
    },
    {
        path: WebUrl._URL_MANAGE_WEB_CONTENT,
        title: 'MANAGE_WEB_CONTENTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_WEB_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageWebContent />
    },
    {
        path: WebUrl._URL_MARQUEE_TAG_CONTENT,
        title: 'MANAGE_MARQUEE_TAG_CONTENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MARQUEE_TAG_CONTENT + AccessRight._READ_PERMISSION_STRING,
        component: () => <MarqueeTagContent />
    },
    {
        path: WebUrl._URL_MARQUEE_TAG_CONTENT_DETAIL,
        title: 'MANAGE_MARQUEE_TAG_CONTENT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MARQUEE_TAG_CONTENT + AccessRight._READ_PERMISSION_STRING,
        component: () => <MarqueeTagContentDetail />
    },
    {
        path: WebUrl._URL_ANNOUNCEMENT,
        title: 'ANNOUNCEMENTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ANNOUNCEMENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageAnnouncement />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_ANNOUNCEMENT,
        title: 'ANNOUNCEMENT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ANNOUNCEMENT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <AnnouncementDetail />
    },
    {
        path: WebUrl._URL_BANNER,
        title: 'BANNERS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANNER_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBanner />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BANNER,
        title: 'BANNER_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANNER_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BannerDetail />
    },
    {
        path: WebUrl._URL_REWARD_BANNER,
        title: 'REWARD_BANNERS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARD_BANNER_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageRewardBanner />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_REWARD_BANNER,
        title: 'REWARD_BANNER_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARD_BANNER_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <RewardBannerDetail />
    },
    {
        path: WebUrl._URL_PROMOTION,
        title: 'PROMOTIONS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PROMOTION_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManagePromotion />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_PROMOTION,
        title: 'PROMOTION_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PROMOTION_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <PromotionDetail />
    },
    {
        path: WebUrl._URL_SEO,
        title: 'SEO',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SEO_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageScript isSeo={true} />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_SEO,
        title: 'SEO_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SEO_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ScriptDetail isSeo={true} />
    },
    {
        path: WebUrl._URL_SCRIPT,
        title: 'SCRIPT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SCRIPT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageScript isSeo={false} />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_SCRIPT,
        title: 'SCRIPT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SCRIPT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ScriptDetail isSeo={false} />
    },
    {
        path: WebUrl._URL_EMAIL,
        title: 'EMAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._EMAIL_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageEmail />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_EMAIL,
        title: 'EMAIL_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._EMAIL_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <EmailDetail />
    },
    {
        path: WebUrl._URL_CONTENT,
        title: 'CONTENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageContent />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_CONTENT,
        title: 'CONTENT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CONTENT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ContentDetail />
    },
    {
        path: WebUrl._URL_VIP_LEVEL,
        title: 'VIP_LEVEL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <VipLevel />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_VIP_LEVEL,
        title: 'VIP_LEVEL_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <VipLevelDetail />
    },
    {
        path: WebUrl._URL_REMARK,
        title: 'REMARK',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REMARK_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <Remark />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_REMARK,
        title: 'REMARK_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REMARK_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <RemarkDetail />
    },
    {
        path: WebUrl._URL_TAG,
        title: 'TAG',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TAG_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <Tag />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_TAG,
        title: 'TAG_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TAG_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <TagDetail />
    },
    {
        path: WebUrl._URL_BANK_PACKAGE,
        title: 'BANK_PACKAGE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_PACKAGE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <BankPackage />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BANK_PACKAGE,
        title: 'BANK_PACKAGE_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_PACKAGE_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BankPackageDetail />
    },
    {
        path: WebUrl._URL_DEFAULT_BET_SETTING,
        title: 'DEFAULT_BET_SETTING',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BET_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <DefaultBetSetting />
    },
    {
        path: WebUrl._URL_USER_PRODUCT,
        title: 'PRODUCT_MAINTENANCE',
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        component: () => <ProductMaintenance />
    },
    {
        path: WebUrl._URL_MANAGE_BONUS,
        title: 'BONUS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBonus />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BONUS,
        title: 'BONUS_DETAIL',
        authRequired: true, 
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BonusDetail />
    }, 
    {
        path: WebUrl._URL_COMPANY_PRODUCT_MAINTENANCE,
        title: 'COMPANY_PRODUCT_MAINTENANCE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._COMPANY_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <CompanyProductMaintenance />
    },
    {
        path: WebUrl._URL_COMPANY_PRODUCT_API_INFO,
        title: 'COMPANY_PRODUCT_API_INFO',
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._COMPANY_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        component: () => <CompanyProductApiInfo />
    },
    {
        path: WebUrl._URL_COMPANY_PRODUCT_ACCOUNT_INFO,
        title: 'COMPANY_PRODUCT_ACCOUNT_INFO',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <CompanyProductAccountInfo />
    },
    {
        path: WebUrl._URL_PRODUCT_CATEGORY,
        title: 'PRODUCT_CATEGORY',
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_CATEGORY_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        component: () => <ManageProductCategory />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_CATEGORY,
        title: 'PRODUCT_CATEGORY_DETAIL',
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_CATEGORY_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        component: () => <ProductCategoryDetail />
    },
    {
        path: WebUrl._URL_PROFIT_LOSS_REPORT,
        title: 'PROFIT_LOSS_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PROFIT_LOSS_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ProfitLossReport />
    },
    {
        path: WebUrl._URL_BET_DETAIL_REPORT,
        title: 'BET_DETAIL_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BET_DETAIL_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BetDetailReport />
    },
    {
        path: WebUrl._URL_USERNAME_BET_DETAIL_REPORT,
        title: 'USERNAME_BET_DETAIL_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BET_DETAIL_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <UsernameBetDetailReport />
    },
    {
        path: WebUrl._URL_DAILY_SUMMARY_REPORT,
        title: 'DAILY_SUMMARY_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DAILY_SUMMARY_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <DailySummaryReport />
    },
    {
        path: WebUrl._URL_CHANNEL_SUMMARY_REPORT,
        title: 'CHANNEL_SUMMARY_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CHANNEL_SUMMARY_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ChannelSummaryReport />
    },
    {
        path: WebUrl._URL_BANK_SUMMARY_REPORT,
        title: 'BANK_SUMMARY_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BANK_SUMMARY_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BankSummaryReport />
    },
    {
        path: WebUrl._URL_WIN_LOSS_REPORT,
        title: 'WINLOSS_REPORT',
        authRequired: true,
        permission: AccessRight._WINLOSS_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageWinLossReport />
    },
    {
        path: WebUrl._URL_TALLY_WIN_LOSS_REPORT,
        title: 'TALLY_WINLOSS_REPORT',
        authRequired: true,
        permission: AccessRight._TALLY_WINLOSS_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageTallyWinLossReport />
    },
    {
        path: WebUrl._URL_WIN_LOSS_REPORT2,
        title: 'WINLOSS_REPORT2',
        authRequired: true,
        permission: AccessRight._WINLOSS_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageWinLossReport2 />
    },
    {
        path: WebUrl._URL_WIN_LOSS_REPORT3,
        title: 'WINLOSS_REPORT',
        authRequired: true,
        permission: AccessRight._WINLOSS_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageWinLossReport3 />
    },
    {
        path: WebUrl._URL_BONUS_COMMISSION_REPORT,
        title: 'BONUS_COMMISSION_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_COMM_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageBonusCommReport />
    }, 
	{
        path: WebUrl._URL_MANAGE_WALLET_REPORT,
        title: 'WALLET_TRANSFER_REPORT',
        authRequired: true,
        permission: AccessRight._WALLET_TRANSFER_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageWalletTransferReport />
    },
    {
        path: WebUrl._URL_MEMBER_REPORT,
        title: 'MEMBER_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <MemberReport />
    },
    {
        path: WebUrl._URL_SERVICE_CHARGE_REPORT,
        title: 'SERVICE_CHARGE_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SERVICE_CHARGE_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ServiceChargeReport />
    },
    {
        path: WebUrl._URL_TRANSACTION_REPORT,
        title: 'TRANSACTION_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSACTION_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <TransactionReport />
    },
    {
        path: WebUrl._URL_WALLET_TRANSFER_REPORT,
        title: 'WALLET_TRANSFER_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WALLET_TRANSFER_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <WalletTransferReport />
    },
    {
        path: WebUrl._URL_IP_MONITOR,
        title: 'IP_MONITOR',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._IP_MONITOR_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageIPMonitor />
    },
    {
        path: WebUrl._URL_PRODUCT_SETTING,
        title: 'PRODUCT_SETTING',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageProductSetting />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_SETTING,
        title: 'PRODUCT_SETTING_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ProductSettingDetail />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT_GAME_SETTING,
        title: 'MANAGE_PRODUCTS_GAME_SETTING',
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <ManageAccountProductGame />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME_SETTING,
        title: 'PRODUCTS_GAME_SETTING_DETAIL',
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <AccountProductGameDetail />
    },
    {
        path: WebUrl._URL_ACTIVITY_LOG,
        title: 'ACTIVITY_LOG',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ACTIVITY_LOG_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ActivityLog />
    },
    {
        path: WebUrl._URL_TRANSACTION_DETAIL,
        title: 'TRANSACTION_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSACTION_ENQUIRY_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <TransactionDetail />
    },
    {
        path: WebUrl._URL_MANAGE_CONTACT_US,
        title: 'CONTACT_US',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CONTACT_US_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageContactUs />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_CONTACT_US,
        title: "CONTACT_US_DETAIL",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CONTACT_US_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ContactUsDetail />
    },
    {
        path: WebUrl._URL_MANAGE_VIP_BENEFIT,
        title: 'VIP_BENEFIT_TITLE',
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <ManageVipBenefit />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_VIP_BENEFIT,
        title: "VIP_BENEFIT_TITLE_DETAIL",
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <VipBenefitDetail />
    },
    {
        path: WebUrl._URL_MANAGE_VIP_BENEFIT_CONTENT,
        title: 'VIP_BENEFIT_CONTENT',
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <ManageVipBenefitContent />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_VIP_BENEFIT_CONTENT,
        title: "VIP_BENEFIT_CONTENT_DETAIL",
        permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <VipBenefitContentDetail />
    },
    {
        path: WebUrl._URL_VIEW_ACCOUNT,
        title: "VIEW_ACCOUNT",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        //permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ViewAccount />
    },
    {
        path: WebUrl._URL_MAINTENANCE,
        exact: true,
        title: 'MAINTENANCE',
        authRequired: false,
        component: () => <Maintenance />
    },
    //{
    //    path: WebUrl._URL_COMMISSION_ADJUSTMENT,
    //    exact: true,
    //    title: 'COMMISSION_ADJUSTMENT',
    //    authRequired: true,
    //    loginType: [LoginType._LOGIN_TYPE_USER],
    //    permission: AccessRight._COMMISSION_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    //    component: () => <CommissionAdjustment />
    //},
    //{
    //    path: WebUrl._URL_BONUS_ADJUSTMENT,
    //    exact: true,
    //    title: 'BONUS_ADJUSTMENT',
    //    authRequired: true,
    //    loginType: [LoginType._LOGIN_TYPE_USER],
    //    permission: AccessRight._BONUS_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    //    component: () => <BonusAdjustment />
    //},
    {
        path: WebUrl._URL_ADJUSTMENT_REPORT,
        exact: true,
        title: 'ADJUSTMENT_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ADJUSTMENT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <AdjustmentReport />
    },
    {
        path: WebUrl._URL_MANAGE_COMMUNICATION,
        exact: true,
        title: 'COMMUNICATION_CHANNELS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_COMMUNICATION_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageCommunication />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_COMMUNICATION,
        exact: true,
        title: 'CREATE_EDIT_COMMUNICATION',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_COMMUNICATION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <CommunicationChannelDetail />
    },
    {
        path: WebUrl._URL_MANAGE_IP_BLOCK,
        exact: true,
        title: 'MANAGE_IP_BLOCK',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_IP_BLOCK_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageIpBlock />
    },
    {
        path: WebUrl._URL_MANAGE_SYSTEM_SETTING,
        exact: true,
        title: 'MANAGE_SYSTEM_SETTING',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SYSTEM_SETTING_ACCESS + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSystemSetting />
    },
    {
        path: WebUrl._URL_VIEW_PRODUCT_ACCOUNT,
        exact: true,
        title: 'VIEW_PRODUCT_ACCOUNT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ViewProductAccount />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_LIST,
        title: 'SHAREHOLDERS_LIST',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ShareholdersList />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_VIEW_ACCOUNT,
        title: "SHAREHOLDER_VIEW_ACCOUNT",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersViewAccount />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_PRODUCTS,
        title: "SHAREHOLDERS_PRODUCTS",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PRODUCT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersProductList />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_PRODUCTS_EDIT,
        title: "SHAREHOLDERS_PRODUCTS_EDIT",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PRODUCT_SETTING_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersViewProduct />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_PROCESS_HUAT_TICKET,
        title: "SHAREHOLDERS_PROCESS_SALES_HUAT_TICKET",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_ADD_HUAT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersHuatTicket />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_PROCESS,
        title: "SHAREHOLDERS_PROCESS_SALES",
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION  + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersProcessSales />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
        title: "SHAREHOLDERS_PAYOUT_EDIT",
        exact: false,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersViewPayout />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_HUAT_TICKET_EDIT,
        title: "SHAREHOLDERS_HUAT_TICKET_EDIT",
        exact: false,
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_ADD_HUAT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ShareholdersViewHuatTicket />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_REPORT,
        title: 'SHAREHOLDERS_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ShareholderProcessSalesReport />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_DOWNLINE_REPORT,
        title: 'SHAREHOLDERS_DOWNLINE_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_DOWNLINE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ShareholderDownlineReport />
    },
    {
        path: WebUrl._URL_MANAGE_RECURRING_JOB,
        title: 'RECURRING_JOBS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._RECURRING_JOB_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageRecurringJob />
    },
    {
        path: WebUrl._URL_MANAGE_MANUAL_JOB,
        title: 'MANUAL_JOBS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._RECURRING_JOB_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageManualJob />
    },
    {
        path: WebUrl._URL_PROCESS_GAME_RECORD_REPORT,
        title: 'PROCESS_GAME_RECORD_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._PROCESS_GAME_RECORD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ProcessGameRecordReport />
    },
    {
        path: WebUrl._URL_568_NON_PROCESS_GAME_RECORD_REPORT,
        title: '568_NON_PROCESS_GAME_RECORD_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._NON_PROCESS_568_GAME_RECORD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <NonProcessGameRecordReport />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_UNCLAIMED_REPORT,
        title: 'SHAREHOLDER_UNCLAIM_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_UNCLAIMED_SALES_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ShareholderUnclaimReport />
    },
    {
        path: WebUrl._URL_SHAREHOLDERS_UNCLAIMED_REPORT_LIST,
        title: 'SHAREHOLDER_UNCLAIM_REPORT_LIST',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_UNCLAIMED_SALES_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ShareholderUnclaimReportList />
    },
    {
        path: WebUrl._URL_COMISSION_REPORT_LIST,
        title: 'NORMAL_COMMISSION_REPORT_LIST',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <CommissionReportList />
    },
    {
        path: WebUrl._URL_TOTAL_SHARE_PAYOUT_REPORT,
        title: 'TOTAL_SHARE_PAYOUT_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SHAREHOLDER_SHARE_PAYOUT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TotalSharePayoutReport />
    },
    {
        path: WebUrl._URL_MANAGE_SKIN_SETTINGS,
        title: 'MANAGE_SKIN_SETTINGS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SKIN_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSkinSettings />
    },
    {
        path: WebUrl._URL_SKIN_SETTINGS_DETAIL,
        title: 'SKIN_SETTINGS_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._SKIN_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SkinSettingsDetail />
    },
    {
        path: WebUrl._URL_MANAGE_VIDEO_TUTORIAL,
        title: 'MANAGE_VIDEO_TUTORIAL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._VIDEO_TUTORIAL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageVideoTutorial />
    },
    {
        path: WebUrl._URL_VIDEO_TUTORIAL_DETAIL,
        title: 'VIDEO_TUTORIAL_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._VIDEO_TUTORIAL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <VideoTutorialDetail />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_WALLET_STATEMENT,
        title: 'MANAGE_MEMBER_WALLET_STATEMENT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_WALLET_STATEMENT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageWalletHistory />
    },
    {
        path: WebUrl._URL_TODAY_NEW_MEMBERS,
        title: 'NEW_MEMBERS_7_DAYS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._NEW_MEMBER_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TodayNewMembers />
    },
    {
        path: WebUrl._URL_TRANSFER_LOG,
        title: 'TRANSFER_LOG',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSFER_LOG_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TransferLog />
    },
    {
        path: WebUrl._URL_TNG_SETTINGS,
        title: 'TNG_SETTINGS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TNG_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TnGSettings />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_PRODUCT_USERNAME,
        title: 'MANAGE_MEMBER_PRODUCT_USERNAME',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_PRODUCT_USERNAME_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberProductUsername />
    },
    {
        path: WebUrl._URL_LIVE_MONITOR,
        title: 'LIVE_MONITOR',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <LiveMonitor />
    },
    {
        path: WebUrl._URL_TRANSACTION_QUEUE,
        title: 'TRANSACTION_QUEUE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSACTION_QUEUE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TransactionQueue />
    },
    {
        path: WebUrl._URL_WITHDRAWAL_SETTINGS,
        title: 'WITHDRAWAL_SETTINGS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WITHDRAWAL_SETTINGS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <WithdrawalSettings />
    },
    {
        path: WebUrl._URL_REWARDS_SCHEDULE,
        title: 'REWARDS_SCHEDULE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <RewardsSchedule />
    },
    {
        path: WebUrl._URL_REWARDS_SETTING,
        title: 'REWARDS_SETTING',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <RewardsSetting />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_FULL_NAME,
        title: 'MANAGE_MEMBER_FULL_NAME',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_FULLNAME_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberFullname />
    },
    {
        path: WebUrl._URL_HELP,
        title: 'HELP',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        component: () => <FAQHelp />
    },
    {
        path: WebUrl._URL_SPORTS_LIVE_MONITOR,
        title: 'SPORTS_LIVE_MONITOR',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SportsLiveMonitor />
    },
    {
        path: WebUrl._URL_MANAGE_568WIN_BET_LIMIT,
        title: 'ADJUST_BET_LIMIT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        //permission: AccessRight._MEMBER_FULLNAME_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <Manage568WinBetLimit />
    },
    {
        path: WebUrl._URL_CLAIMED_REWARDS_REPORT,
        title: 'CLAIMED_UNCLAIMED_REWARDS_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ClaimedRewardsReport />
    },
    {
        path: WebUrl._URL_FORFEITED_REWARDS_REPORT,
        title: 'FORFEITED_REWARDS_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ForfeitedRewardsReport />
    },
    {
        path: WebUrl._URL_SPORTS_TEAM_BET_REPORT,
        title: 'SPORTS_TEAM_BET_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <SportsTeamBetReport />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_PO,
        title: 'MANAGE_MEMBER',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_MEMBER_PO_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberPO />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_CS,
        title: 'MANAGE_MEMBER',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_MEMBER_CS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberCS />
    },
    {
        path: WebUrl._URL_RETRIEVE_MEMBER_BET_DATA,
        title: 'RETRIEVE_MEMBER_BET_DATA',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._RETRIEVE_GAME_DATA_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <RetrieveMemberBetData />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_WALLET_STATEMENT_RECON,
        title: 'MEMBER_WALLET_STATEMENT_RECON',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_WALLET_STATEMENT_RECON_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageWalletRecon />
    },
    {
        path: WebUrl._URL_DEPOSIT_REFERENCE,
        title: 'DEPOSIT_REFERENCE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_REFERENCE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <DepositReference />
    },
    {
        path: WebUrl._URL_MANAGE_MOBILE_TAC,
        title: 'MANAGE_MOBILE_TAC',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_MOBILE_TAC_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMobileTAC />
    },
    {
        path: WebUrl._URL_TRANSACTION_SUMMARY_REPORT,
        title: 'TRANSACTION_SUMMARY_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._TRANSACTION_SUMMARY_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <TransactionSummaryReport />
    },
    {
        path: WebUrl._URL_MANAGE_DEPOSIT_PROMOTION,
        title: 'MANAGE_DEPOSIT_PROMOTION',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_PROMOTION_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageDepositPromotion />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_DEPOSIT_PROMOTION,
        title: 'DEPOSIT_PROMOTION_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_PROMOTION_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <DepositPromotionDetail />
    },
    {
        path: WebUrl._URL_BIRTHDAY_REWARDS,
        title: 'MANAGE_BIRTHDAY_REWARDS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_BIRTHDAY_REWRDS + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBirthdayRewards />
    },
    {
        path: WebUrl._URL_MEMBER_REBATE,
        title: 'MANAGE_MEMBER_REBATE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_BIRTHDAY_REWRDS + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberRebate />
    },
    {
        path: WebUrl._URL_MEMBER_WEEKLY_RESCUE,
        title: 'MANAGE_MEMBER_WEEKLY_RESCUE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_MEMBER_WEEKLY_RESCUE + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberWeeklyRescue />
    },
    {
        path: WebUrl._URL_WEEKLY_RESCUE,
        title: 'MANAGE_WEEKLY_RESCUE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WEEKLY_RESCUE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageWeeklyRescue />
    },
    {
        path: WebUrl._URL_WEEKLY_RESCUE_DETAIL,
        title: 'WEEKLY_RESCUE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._WEEKLY_RESCUE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <WeeklyRescueDetail />
    },
    {
        path: WebUrl._URL_BIRTHDAY_REWARD_REPORT,
        title: 'BIRTHDAY_REWARD_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
         permission: AccessRight._BIRTHDAY_REWARD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
         component: () => <BirthdayRewardReport />
    },
    {
        path: WebUrl._URL_GIFTPACE_REPORT,
        title: 'GIFTPACE_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._GIFTPACE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <GiftpaceReport />
    },
    {
        path: WebUrl._URL_MANAGE_DAILY_CHECK_IN,
        title: 'MANAGE_DAILY_CHECK_IN',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DAILY_CHECK_IN_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageDailyCheckIn />
    },
    {
        path: WebUrl._URL_DAILY_CHECK_IN_DETAIL,
        title: 'DAILY_CHECK_IN',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DAILY_CHECK_IN_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <DailyCheckInDetail />
    },
    {
        path: WebUrl._URL_MANAGE_DC_ITEM_LIST,
        title: 'DC_ITEM_LIST',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DC_ITEM_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageDCItemList />
    },
    {
        path: WebUrl._URL_MANAGE_DC_REDEMPTION,
        title: 'DC_REDEMPTION',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DC_REDEMPTION_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageDCRedemption />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_DC_ITEM,
        title: 'DC_ITEM_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DC_ITEM_LIST_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <DCItemDetail />
    },
    {
        path: WebUrl._URL_MANAGE_DC_POINTS,
        title: 'DC_POINTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DC_POINTS_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <ManageDCPoints />
    },
    {
        path: WebUrl._URL_UPDATE_DC_POINTS,
        title: 'DC_POINT_DETAILS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DC_POINTS_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <DCPointDetails />
    },
    {
        path: WebUrl._URL_MANAGE_MEMBER_DC_POINTS,
        title: 'MEMBER_POINTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_DC_POINTS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberDCPoints />
    },
    {
        path: WebUrl._URL_MEMBER_DC_POINTS_DETAIL,
        title: 'MEMBER_DC_POINTS_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_DC_POINTS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <MemberDCPointsDetail />
    },
    {
        path: WebUrl._URL_ACTIVE_MEMBER_REPORT,
        title: 'ACTIVE_MEMBER_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ACTIVE_MEMBER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ActiveMemberReport />
    },
    {
        path: WebUrl._URL_MEMBER_GAME_POINTS,
        title: 'MANAGE_MEMBER_GAME_POINTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MANAGE_MEMBER_GAME_POINTS + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMemberGamePoints />
    },
    {
        path: WebUrl._URL_POINTS_ADJUSTMENT,
        title: 'ADJUSTMENT_MEMBER_POINTS',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._ADJUSTMENT_POINTS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <PointsAdjustment />
    },
    {
        path: WebUrl._URL_POINTS_ADJUSTMENT_REPORT,
        exact: true,
        title: 'POINTS_ADJUSTMENT_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._POINTS_ADJUSTMENT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <PointsAdjustmentReport />
    },
    {
        path: WebUrl._URL_MEMBER_POINTS_REPORT,
        exact: true,
        title: 'MEMBER_POINTS_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_POINTS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <MemberPointsReport />
    },
    {
        path: WebUrl._URL_MANAGE_DEPOSIT_PROMOTION_CATEGORY,
        title: 'MANAGE_DEPOSIT_PROMOTION_CATEGORY',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_PROMOTION_CATEGORY_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageDepositPromotionCategory />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_CATEGORY,
        title: 'DEPOSIT_PROMOTION_CATEGORY_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._DEPOSIT_PROMOTION_CATEGORY_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <DepositPromotionCategoryDetail />
    },
    {
        path: WebUrl._URL_MEMBER,
        title: 'MANAGE_MEMBER',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageMember2 />
    },
    {
        path: WebUrl._URL_CREDIT_REDEMPTION_REPORT,
        title: 'CREDIT_REDEMPTION_REPORT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CREDIT_REDEMPTION_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <CreditRedemptionReport />
    },
    {
        path: WebUrl._URL_BONUS_DEPOSIT,
        title: 'BONUS_DEPOSIT',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_DEPOSIT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageBonusDeposit />
    },
    {
        path: WebUrl._URL_CREATE_OR_UPDATE_BONUS_DEPOSIT,
        title: 'BONUS_DEPOSIT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._BONUS_DEPOSIT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
        component: () => <BonusDepositDetail />
    },
    {
        path: WebUrl._URL_PLAYER_RECONCILIATION,
        title: 'PLAYER_RECONCILIATION',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <PlayerReconciliation />
    },
    {
        path: WebUrl._URL_MANAGE_CAHE_SPHERE,
        title: 'MANAGE_CHAT_SPHERE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CHAT_SPHERE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageChatSphere />
    },
    {
        path: WebUrl._URL_MANAGE_CAHE_SPHERE_TYPE,
        title: 'MANAGE_CHAT_SPHERE_TYPE',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CHAT_SPHERE_TYPE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageChatSphereType />
    },
    {
        path: WebUrl._URL_CHAT_SPHERE_TYPE_DETAIL,
        title: 'MANAGE_CHAT_SPHERE_TYPE_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
        permission: AccessRight._CHAT_SPHERE_TYPE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ChatSphereTypeDetail />
    },
    {
        path: WebUrl._URL_MANAGE_CAHE_SPHERE_CONTENT_DETAIL,
        title: 'CHAT_SPHERE_CONTENT_DETAIL',
        authRequired: true,
        loginType: [LoginType._LOGIN_TYPE_USER],
      permission: AccessRight._CHAT_SPHERE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
      component: () => <ChatSphereContentDetail />
  },
  {
    path: WebUrl._URL_CHAT_SPHERE_PHONE_NUMBER,
    title: 'MANAGE_CHAT_SPHERE_PHONE_NUMBER',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_SPHERE_PHONE_NUMBER_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <ManageChatSpherePhoneNumber />
  },
  {
    path: WebUrl._URL_CHAT_SPHERE_PHONE_NUMBER_DETAIL,
    title: 'MANAGE_CHAT_SPHERE_PHONE_NUMBER_DETAIL',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_SPHERE_PHONE_NUMBER_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <ChatSpherePhoneNumberDetail />
  },
  {
    path: WebUrl._URL_ADJUSTMENT_BONUS_WALLET,
    title: 'ADJUSTMENT_BONUS_WALLET',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <AdjustmentBonusWallet />
  },
  {
    path: WebUrl._URL_MANAGE_MEMBER_BANK_ACCOUNT,
    title: 'MANAGE_MEMBER_BANK_ACCOUNT',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <ManageMemberBankAccount />
  },
  {
    path: WebUrl._URL_MEMBER_BANK_ACCOUNT_DETAIL,
    title: 'MEMBER_BANK_ACCOUNT_DETAIL',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <MemberBankAccountDetail />
  },
  {
    path: WebUrl._URL_MANAGE_ACCOUNT_PRODUCT_RANK,
    title: 'PRODUCT_RANKING',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <ManageProductRanking />
  },
  {
    path: WebUrl._URL_MANAGE_PRODUCT_GAME_RANK,
    title: 'PRODUCT_GAME_SETTING',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <CustomGamesRanking />
  },
  {
    path: WebUrl._URL_GAME_LIST,
    title: 'GAME_LIST',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <GameList />
  },
  {
    path: WebUrl._URL_CLAIMED_PROMOTION_DETAIL,
    title: 'Claimed Promotion Detail',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    //permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <ClaimedPromotionDetails />
  },
  {
    path: WebUrl._URL_MANAGE_MLM_COMMISSION,
    title: 'MANAGE_MLM_COMMISSION',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._GENERATE_COMMISSION_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <ManageMLMCommission />
  },
  {
    path: WebUrl._URL_CHAT_SYSTEM_TEMPALTE,
    title: 'CHAT_TEMPLATE_DETAIL',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_SYSTEM_TEMPLATE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <ChatTemplate />
  },
  {
    path: WebUrl._URL_CHAT_CUSTOM_TEMPALTE,
    title: 'CHAT_CUSTOM_TEMPLATE',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_CUSTOM_TEMPLATE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <CustomChatTemplate />
  },
  {
    path: WebUrl._URL_CREATE_OR_UPDATE_CHAT_SYSTEM_TEMPLATE,
    title: 'CHAT_TEMPLATE_DETAIL',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_SYSTEM_TEMPLATE_PERMISSION + AccessRight._READ_PERMISSION_STRING,
    component: () => <ManageChatSystemTemplate />
  },
  {
    path: WebUrl._URL_MANAGE_CUSTOM_CHAT_TEMPLATE,
    title: 'CHAT_CUSTOM_TEMPLATE',
    authRequired: true,
    loginType: [LoginType._LOGIN_TYPE_USER],
    permission: AccessRight._CHAT_CUSTOM_TEMPLATE_PERMISSION + AccessRight._WRITE_PERMISSION_STRING,
    component: () => <ManageCustomChatTemplate />
  },
];