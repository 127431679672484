import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import { navigateTo } from "../../util/Util.js";
import {
	ApiUrl,
	ApiKey,
	WebUrl,
	AccessRight,
	LanguageKey,
} from "../../util/Constant.js";
import NavigationButton from "../../components/constantComponent/button/NavigationButton.jsx";
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

const CustomChatTemplate = (props) => {
	let _history = useHistory();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [contentData, setContentData] = useState([]);

	let _tableColumns = [
		{
			Header: t("TITLE"),
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: t("CONTENT"),
			accessor: "content",
			disableSortBy: true,
		},
		{
			Header: t("STATUS"),
			accessor: "status",
			disableSortBy: true,
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					{row.original.status == true ? (
						<span className="text-approved font-bold">{t("ACTIVE")}</span>
					) : (
						<span className="text-rejected font-bold">{t("INACTIVE")}</span>
					)}
				</div>
			),
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(
					AccessRight._LIVE_CHAT_CUSTOM_TEMPLATE_PERMISSION,
					true
				) && (
					<div className="btn-group m-r-5 m-b-5">
						<a
							className="btn btn-default"
							onClick={() => {
								navigateTo(
									_history,
									WebUrl._URL_MANAGE_CUSTOM_CHAT_TEMPLATE,
									row.original
								);
							}}
						>
							{t("EDIT")}
						</a>
					</div>
				),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	useEffect(() => {
		fetch(ApiUrl._API_GET_CUSTOM_CHAT_TEMPLATE + "?all=true", {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setContentData(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	}, []);

	return (
		<div style={{ padding: "10px 0 0" }}>
			<h1 className="page-header">
				<div className="page-title">
					{t("CHAT_CUSTOM_TEMPLATE")}
					<NavigationButton
						history={_history}
						url={WebUrl._URL_MANAGE_CUSTOM_CHAT_TEMPLATE}
						buttonText={t("ADD")}
					/>
				</div>
				<div className="page-actions"></div>
			</h1>
			<Panel>
				<ReactTable data={contentData} columns={_tableColumns} />
			</Panel>
		</div>
	);
};

export default CustomChatTemplate;
