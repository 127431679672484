import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageChatSystemTemplateLanguage from "./ManageChatSystemTemplateLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ChatSystemTemplate = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();
  const [announcementId, setAnnouncementId] = useState("");
  const [announcementRefId, setAnnouncementRefId] = useState("");
  const [languageOption, setLanguageOption] = useState([]);

  const [defaultContent, setDefaultContent] = useState("");
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  const [language, setLanguage] = useState();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setTitle("");
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    console.log(_location.state)
    if (_location.state) {
      let templateCode = _location.state.code;
      var apiUrl = ApiUrl._API_GET_CHAT_TEMPLATE_CODE;
      apiUrl += "?code=" + templateCode;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setDefaultContent(responseJson[ApiKey._API_DATA_KEY]["defaultContent"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            setCode(responseJson[ApiKey._API_DATA_KEY]["code"]);

            fetch(ApiUrl._API_GET_LANGUAGE, {
              method: ApiKey._API_GET,
              headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
              },
            })
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  const languageList = [];
                  responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
                    languageList.push({ label: language.name, value: language.id });
                  });

                  setLanguageOption(languageList);
                }
              });

          }
        });
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {

    if (!_location.state) {

      _dispatch(
        showMessage(
          0,
          "Error"
        )
      );

      return false;
    }

    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_CHAT_SYSTEM_TEMPLATE, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: JSON.stringify({
        code: _location.state.code,
        content: data.content,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          _history.goBack();
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        <div className="page-title">
          <NavigationButton history={_history} />
          Edit Chat System Template
        </div>
        <div className="page-actions"></div>
      </h1>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-2 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={title}
                              disabled="disabled"
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-2 col-form-label">
                            <b>{t("Default")}</b>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              defaultValue={defaultContent}
                              disabled="disabled"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {languageOption.map((lang, index) => (
                      <div key={"lang" + lang.value} className="row">
                        <div className="col-lg-8">
                          <div className="form-group row m-b-15">
                            <label className="col-md-2 col-form-label">
                              <b>{lang.label} {t("CONTENT")}</b>
                            </label>
                            <div className="col-md-7">
                              <textarea
                                className="form-control"
                                rows="5"
                                style={{ marginTop: "10px" }}
                                ref={register}
                                name={`content['l${lang.value}']`} // Making name dynamic
                                defaultValue={content && content.find(item => item.id === lang.value)?.content || ""}
                                onChange={(e) => {
                                  var templang = { ...language };
                                  templang[lang.value] = e.target.value
                                  setLanguage(templang);
                                  setValue(`content['l${lang.value}']`, e.target.value.toString()); // Making setValue dynamic
                                }}
                              ></textarea>
                              {errors[`content['l${lang.value}']`] && (
                                <div className="invalid-feedback">
                                  {t(errors[`content['l${lang.value}']`].message)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <hr />
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary pull-right">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {announcementId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageChatSystemTemplateLanguage
                  id={announcementId}
                  type={ContentTypeString._ANNOUNCEMENT}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ChatSystemTemplate;
