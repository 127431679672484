import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { navigateTo } from '../../util/Util';
import { ApiKey, ApiUrl, WebUrl, AccessRight, TransactionType, TransactionStatus, PanelType, LanguageKey, DEFAULT_PAGE_SIZE, ReadWritePermission, Role } from '../../util/Constant';
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat, createMultiPartFormBody } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Deposit from './Deposit.js';
import Withdrawal from './Withdrawal.js';
import Adjustment from './Adjustment.js';
import ApiEngine from '../../util/ApiEngine';
import ImageViewer from 'react-simple-image-viewer';
import { AsyncPaginate } from "react-select-async-paginate";
import { checkIfPermissionExist } from '../../util/PermissionChecker';
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";


/// <summary>
/// Author : -
/// </summary>

const TransactionEnquiry = props => {
  const _DEPOSIT = 1;
  const _WITHDRAWAL = 2;
  const _ADJUSTMENT = 3;
  const _NORMAL_TRANSACTION_TAB = 1;
  const _GATEWAY_TRANSACTION_TAB = 2;
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors } = useForm();
  const [calendarStartDate, setCalendarStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [withdrawalApprovalModal, setWithdrawalApprovalModal] = useState(false);
  const [auditBankOption, setAuditBankOption] = useState([]);
  const [auditBankId, setAuditBankId] = useState('');
  const [auditBankAccountId, setAuditBankAccountId] = useState('');
  const [transaction, setTransaction] = useState();
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [showTransButton, setShowTransButton] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const [transactionComponent, setTransactionComponent] = useState();
  const [memberId, setMemberId] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [transactionApiUrl, setTransactionApiUrl] = useState("");
  const [receiptImages, setReceiptImages] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const _userData = useSelector((state) => state.authState.userData);
  const isCompany = _userData.userRoleId === Role._SUPER_COMPANY || _userData.userRoleId === Role._COMPANY;
  let role1IsAllowed = checkIfPermissionExist(
    AccessRight._PAYMENT_GATEWAY_APPROVAL_PERMISSION,
    true
  );
  let role2IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_QUEUE_PERMISSION,
    true
  );
  let role3IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_APPROVAL_PERMISSION,
    true
  );
  let role4IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_REJECTION_PERMISSION,
    true
  );

  const _STATUS_OPTIONS = [
    { value: TransactionStatus._OPEN, label: 'Open' },
    { value: TransactionStatus._APPROVED, label: 'Approved' },
    { value: TransactionStatus._REJECTED, label: 'Rejected' },
  ];

  const _TRANSACTION_OPTIONS = [
    { value: TransactionType._DEPOSIT, label: 'Deposit' },
    { value: TransactionType._WITHDRAWAL, label: 'Withdrawal' },
    { value: TransactionType._UPLINE_TRANSFER, label: 'Transfer' },
    { value: TransactionType._SHAREHOLDER_CLAIM, label: 'Shareholder Claim' },
    { value: TransactionType._COMMISSION, label: 'Commission' },
    { value: TransactionType._REWARD, label: "Reward Claim" },
    { value: TransactionType._BIRTHDAY_REWARD, label: "Birthday Reward" },
    { value: TransactionType._VIP_COMMISSION, label: "VIP Commission" },
    /*{ value: TransactionType._WEEKLY_RESCUE_BONUS, label: "Weekly Rescue" },
    { value: TransactionType._CREDIT_REDEMPTION, label: "Credit Redemption" },*/
    { value: TransactionType._REFERRAL_BONUS, label: 'Referral Bonus' },
  ];

  const _PANEL_OPTIONS = [
    { value: PanelType._MEMBERSITE, label: 'MemberSite' },
    { value: PanelType._BACKOFFICE, label: 'BackOffice' },
  ];

  const _DEPOSIT_METHODS = [
    { value: 'Bank', label: 'Bank' },
    { value: 'TOPKASH', label: 'TOPKASH' },
    { value: 'HuiOne', label: 'HuiOne' },
    { value: 'Crypto', label: 'Crypto Wallets' },
    { value: 'Wing Weiluy', label: 'Wing Weiluy' },
  ];

  const [bankingChannelOption, setBankingChannelOption] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([{ value: TransactionStatus._OPEN, label: 'Open' }]);
  const [selectedTransaction, setSelectedTransaction] = useState(_TRANSACTION_OPTIONS);
  const [selectedPanel, setSelectedPanel] = useState(_PANEL_OPTIONS);
  const [selectedDepositMethod, setSelectedDepositMethod] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [username, setUsername] = useState("");
  const [label, setLabel] = useState("");
  const [bank, Setbank] = useState("");
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [activeTab, setActiveTab] = useState(_NORMAL_TRANSACTION_TAB);
  const [adjustedAmount, setAdjustedAmount] = useState(0);
  const [adjustedAmountError, setAdjustedAmountError] = useState(false);


  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  let _exportHeaders2 = [
    { label: "Transaction DateTime", key: "transactionDateTime" },
    { label: "Action", key: "action" },
    { label: "Amount", key: "amount" },
    { label: "Account Balance Before", key: "prePrimaryCreditBalance" },
    { label: "Account Balance After", key: "postPrimaryCreditBalance" },
    { label: " ", key: " " },
    { label: "Bonus Wallet Amount", key: "bonusWalletAmount" },
    { label: "Bonus Wallet Before", key: "preBonusWalletBalance" },
    { label: "Bonus Wallet After", key: "postBonusWalletBalance" },
  ];

  const [exportDatas2, setExportDatas2] = useState([]);

  const [wingWeiluyApprovalModal, setWingWeiluyApprovalModal] = useState(false);
  const [receiptImage, setReceiptImage] = useState();
  const [receiptFile, setReceiptFile] = useState([]);
  const [isNewReceiptImage, setIsNewReceiptImage] = useState(false);


  useEffect(() => {
    sessionStorage.setItem(_SELECTED_MEMBER_KEY, username);
  }, [username]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    console.log(props);
    init();
    register({ name: 'auditBankId' }, { required: "PLEASE_SELECT_BANK" });
    register({ name: 'auditBankAccountId' }, { required: "PLEASE_SELECT_BANK_ACCOUNT" });
  }, [props.username]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFlag) {
      getUserTransaction(activeTab);
    }
  }, [initFlag]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    switch (transactionType) {
      case _DEPOSIT:
        setTransactionComponent(<Deposit id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      case _WITHDRAWAL:
        setTransactionComponent(<Withdrawal id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      case _ADJUSTMENT:
        setTransactionComponent(<Adjustment id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      default:
        setTransactionComponent();
        break;
    }
  }, [transactionType]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(auditBankId)) {
      getBankAccount();
    }
  }, [auditBankId])

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });
      formattedData.unshift({ label: "All", value: "" })
      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBankAccount() {
    var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
    apiUrl += "?bankId=" + auditBankId + "&withdrawal=" + true;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        if (bankAccount.withdrawalStatus) {
          bankAccountList.push({ label: bankAccount.accountNumber, value: bankAccount.id });
        }
      });

      setBankAccountOption(bankAccountList);
    }
    else {
      _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }

  function prepareReceiptImage(imgString) {
    if (imgString != null) {
      let imgArray = [];
      //imgString = "../.." + imgString;
      imgArray.push(imgString);
      setReceiptImages(imgArray);
      openImageViewer(0);
    }
    else {
      _dispatch(showMessage(false, t('NO_RECEIPT_FOUND')));
    }
  }


  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    if (props.username && props.id) {
      setUsername(props.username);
      setMemberId(props.id);
      setLabel(props.username);
      if (isCompany || _userData.userRoleId === Role._PAYMENT_OFFICIAL_CS) { setShowTransButton(true); }
    }
    try {
      let channelResponseJson = await ApiEngine.get(ApiUrl._API_GET_BANKING_CHANNEL);
      if (channelResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankingChannelList = [];
        channelResponseJson[ApiKey._API_DATA_KEY].forEach(function (bankingChannel) {
          bankingChannelList.push({ label: bankingChannel.name, value: bankingChannel.name });
        });

        setBankingChannelOption(bankingChannelList);
      }
      else {
        throw channelResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      let userBankResponseJson = await ApiEngine.get(ApiUrl._API_GET_USER_BANK);
      if (userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankList = [];
        userBankResponseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
          bankList.push({ label: bank.bankName, value: bank.id });
        });
        setAuditBankOption(bankList);
      }
      else {
        throw userBankResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setInitFlag(true);
    }
    catch (ex) {
      _dispatch(showMessage(false, t(ex)));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getUserTransaction = async (tab) => {
    let status = [];
    let transactionType = [];
    let channel = [];
    let panel = [];
    let depositMethod = [];
    if (!stringIsNullOrEmpty(selectedStatus)) {
      selectedStatus.map((selected) => {
        status.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedTransaction)) {
      selectedTransaction.map((selected) => {
        transactionType.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedChannel)) {
      selectedChannel.map((selected) => {
        channel.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedPanel)) {
      selectedPanel.map((selected) => {
        panel.push(selected.value);
      })
    }

    if (!stringIsNullOrEmpty(selectedDepositMethod)) {
      selectedDepositMethod.map((selected) => {
        depositMethod.push(selected.value);
      })
    }

    let fetchUrl = ApiUrl._API_GET_ALL_USER_TRANSACTION;
    fetchUrl += "?startDate=" + calendarStartDate + "&endDate=" + calendarEndDate + "&approvalStatus=" + status + "&isGatewayTrans=" + (tab === _GATEWAY_TRANSACTION_TAB ? true : false);
    fetchUrl += "&transactionType=" + transactionType + "&depositMethod=" + depositMethod + "&bankingChannel=" + channel + "&panel=" + panel + "&v=" + Date.now();
    if (username !== "") {
      fetchUrl += "&username=" + (label == "All" ? "" : label);
    }
    setTransactionApiUrl(fetchUrl);
  }

  // If is debit transaction and searched username is not row's username (means transffered to this searched username)
  // If is debit transaction and searched username is empty (viewing from overview)
  // If is transfer transaction and searched username is row's username (means transferred to this searched username)
  const isDebitTransaction = (transaction) => {
    return (_DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) >= 0 && transaction.memberUsername == username) ||
      (_DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) >= 0 && stringIsNullOrEmpty(username)) ||
      (transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER && transaction.memberUsername == username) ||
      (transaction.transactionTypeId == TransactionType._WITHDRAWAL);
  }

  // If is not debit transaction 
  // If it is transfer type and username is not transaction username and searched username is not empty (viewing from account)
  const isCreditTransaction = (transaction) => {
    return _DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) < 0 ||
      (transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER && transaction.memberUsername != username && !stringIsNullOrEmpty(username))
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const updateStatus = async (transaction, status, auditBankAccountId) => {
    let apiUrl = ApiUrl._API_UPDATE_TRANSACTION_STATUS
    apiUrl += "?transactionId=" + transaction.id + "&status=" + status;
    if (!stringIsNullOrEmpty(auditBankAccountId)) {
      apiUrl += "&auditBankAccountId=" + auditBankAccountId;
    }

    let responseJson = await ApiEngine.post(apiUrl);
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    getUserTransaction(activeTab);
  }

  const _DEBIT_TRANSACTIONS = [TransactionType._WITHDRAWAL, TransactionType._UPLINE_TRANSFER];

  let _tableColumns = [
    {
      id: "id",
      Header: "ID",
      accessor: "id",
      minWidth: 100,
      Footer: () => {
        return <span><b>{t("TOTAL")}: </b></span>
      },
    },
    {
      Header: t("DATE"),
      accessor: "submitTime",
      style: {
        whiteSpace: 'unset',
        wordBreak: 'break-word'
      },
      Cell: ({ row }) => {
        return <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
      },
      minWidth: 100
    },
    {
      Header: t("USERNAME"),
      accessor: "memberUsername",
      minWidth: 100,
    },
    {
      Header: t("EN_FULLNAME"),
      accessor: "memberFullName",
      minWidth: 100
    },
    /*{
      Header: t("CN_FULLNAME"),
      accessor: "memberCNFullName",
      minWidth: 100
    },*/
    {
      Header: t("TYPE"),
      accessor: "transactionType",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.transactionTypeId == 6 && row.original.transferTo != username) {
          return <>{t(row.original.transactionType + " To " + row.original.transferTo)}</>

        }
        else if (row.original.transactionTypeId == 6 && row.original.transferTo == username) {
          return <>{t(row.original.transactionType + " To " + row.original.transferTo)}</>

        }
        else if (row.original.transactionTypeId == 1) {
          return <>{t(row.original.transactionType)}<br /> <div style={{ fontSize: "10px" }}>{t(row.original.bank)}</div></>
        }
        else {
          return <>{t(row.original.transactionType)}</>
        }
      }
    },
    {
      Header: t("STATUS"),
      accessor: "approvalStatusString",
      Cell: ({ row }) => {
        switch (row.original.approvalStatus) {
          case TransactionStatus._APPROVED:
            return <span className="badge badge-secondary badge-green">{t("APPROVED")}</span>
            break;
          case TransactionStatus._REJECTED:
            return <span className="badge badge-secondary badge-danger">{t("REJECTED")}</span>
            break;
          default:
            return <span className="badge badge-secondary badge-primary">{t(row.original.approvalStatusString)}</span>
            break;
        }
      },
      minWidth: 100
    },
    {
      id: "credit",
      Header: t("CREDIT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (isCreditTransaction(row.original)) {
          return <>{numberWithCurrencyFormat(parseFloat(row.original.amount), 3)}</>
        }
        else {
          return <></>
        }
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.amount && isCreditTransaction(currentValue.original)) {
            return sum + parseFloat(currentValue.original.amount);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
      }
    },
    /*{
      id: "serviceChargePercentage",
      Header: t("SERVICE_CHARGE_PERCENTAGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.serviceChargePercentage))}</>
      },
      Footer: ''
    },
    {
      id: "serviceCharge",
      Header: t("SERVICE_CHARGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.serviceCharge))}</>
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.serviceCharge) {
            return sum + parseFloat(currentValue.original.serviceCharge);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum))}</b></span>
      }
    },*/
    {
      id: "debit",
      Header: t("DEBIT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (isDebitTransaction(row.original)) {
          return <>-{numberWithCurrencyFormat(parseFloat(row.original.amount))}</>
        }
        else {
          return <></>
        }
      },
      Footer: ({ page }) => {
        const debitSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.amount && isDebitTransaction(currentValue.original)) {
            return sum + (parseFloat(currentValue.original.amount) * -1);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b></span>
      }
    },
    {
      Header: t("IP"),
      accessor: "ipAddress",
      Cell: ({ row }) => {
        return <a href="#">{row.original.ipAddress}</a>
      },
      width: 150
    },
    {
      Header: t("AUDITOR"),
      accessor: "auditedFullName",
      minWidth: 100
    },
    {
      Header: t("CHANNEL"),
      accessor: "channel",
      minWidth: 100
    },
    /*{
      Header: t("PANEL"),
      accessor: "panel",
      Cell: ({ row }) => {
        return <>{t(row.original.panel.toUpperCase())}</>
      },
      minWidth: 100
    },*/
    {
      Header: t("LOCKED_BY"),
      accessor: "lockedByFullName",
    },
    {
      Header: t("LOCKED_TIME"),
      accessor: "lockedTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{!stringIsNullOrEmpty(row.original.lockedTime) ? moment(row.original.lockedTime).format("YYYY-MM-DD HH:mm:ss") : ""}</>
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
            <DropdownMenu>
              {row.original.approvalStatus == TransactionStatus._OPEN && <>
                {/*for non adjustment payment*/}
                {row.original.isInstant !== true && role3IsAllowed && row.original.enableAdjustment !== true && < DropdownItem onClick={() => { row.original.transactionTypeId == TransactionType._WITHDRAWAL ? handleWithdrawalApproval(row.original) : updateStatus(row.original, TransactionStatus._APPROVED, null); }}><span className="text-success">{t("APPROVE")}</span></DropdownItem>}
                {/*for adjustment payment*/}
                {row.original.isInstant !== true && role3IsAllowed && row.original.enableAdjustment == true && < DropdownItem onClick={() => { handleWingWeiluyApproval(row.original)  }}><span className="text-success">{t("APPROVE")}</span></DropdownItem>}
                {row.original.isInstant !== true ?
                  <>{role4IsAllowed ? <DropdownItem onClick={() => { updateStatus(row.original, TransactionStatus._REJECTED, null) }}><span className="text-danger">{t("REJECT")}</span></DropdownItem> : <></>}</>
                  :
                  <>{role1IsAllowed ? <DropdownItem onClick={() => { updateStatus(row.original, TransactionStatus._REJECTED, null) }}><span className="text-danger">{t("REJECT")}</span></DropdownItem> : <></>}</>
                }
              </>}
              <DropdownItem onClick={() => { processLockTransaction(row.original, true) }}><span className="text-warning">{t("VIEW_TRANSACTION")}</span></DropdownItem>
              {(row.original.transactionTypeId == TransactionType._DEPOSIT || (row.original.transactionTypeId == TransactionType._WITHDRAWAL && row.original.receipt !=null)) && <>
                <DropdownItem onClick={() => { processLockTransaction(row.original, false) }}><span className="text-warning">{t("VIEW_RECEIPT")}</span></DropdownItem>
              </>}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" }
    }
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  async function processLockTransaction(transaction, viewTransaction = true) {
    try {
      let responseJson = await ApiEngine.post(ApiUrl._API_PROCESS_LOCK_TRANSACTION + "?transactionId=" + transaction["id"]);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (viewTransaction) {
          navigateTo(_history, WebUrl._URL_TRANSACTION_DETAIL, transaction);
        }
        else if (transaction.receipt.indexOf('.pdf') != -1) {
          // for download receipt if pdf
          window.open(transaction.receipt)
        }
        else {
          /// for view receipt
          prepareReceiptImage(transaction.receipt);
        }
      }
      else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    }
    catch (error) {
      _dispatch(showMessage(false, error));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleWithdrawalApproval = (transaction) => {
    setTransaction(transaction);
    setWithdrawalApprovalModal(!withdrawalApprovalModal)
  }

  const handleWingWeiluyApproval = (transaction) => {
    setTransaction(transaction);
    setWingWeiluyApprovalModal(!wingWeiluyApprovalModal)
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = (data, e) => {
    updateStatus(transaction, TransactionStatus._APPROVED, auditBankAccountId);
    setWithdrawalApprovalModal(!withdrawalApprovalModal)
    setTransaction();
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setAuditBankAccountId('');
    setAuditBankId('');
    register({ name: 'auditBankId' }, { required: "PLEASE_SELECT_BANK" });
    register({ name: 'auditBankAccountId' }, { required: "PLEASE_SELECT_BANK_ACCOUNT" });
  }, [withdrawalApprovalModal])

  const exportToExcel = async () => {
    let status = [];
    let newFilteredDatas = [];
    let apiUrl =
      ApiUrl._API_GET_MEMBER_ACCOUNT_BALANCE_JOURNEY;

      apiUrl += "?memberId=" + props.id;

    await fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        'Content-Type': ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      },
    }).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson[ApiKey._API_DATA_KEY])

      responseJson[ApiKey._API_DATA_KEY].map((item, index) => {
        let newFilteredData = {
          transactionDateTime: moment(item.transactionDateTime).format("YYYY-MM-DD HH:mm:ss") ,
          action: item.action,
          amount: item.amount,
          prePrimaryCreditBalance: item.prePrimaryCreditBalance,
          postPrimaryCreditBalance: item.postPrimaryCreditBalance,
          preBonusWalletBalance: item.preBonusWalletBalance,
          postBonusWalletBalance: item.postBonusWalletBalance,
          bonusWalletAmount: item.bonusWalletAmount
        };

        newFilteredDatas.push(newFilteredData)
      })
    }, []);
    setExportDatas2(newFilteredDatas);

    var myButton = document.getElementById('csvBtn');

    if (newFilteredDatas.length > 0) {
      myButton.click();
    }

  };

  const handleAdjustedAmountChange = (event) => {
    const adjustedAmount = event.target.value;
    setAdjustedAmount(event.target.value)
    // Check if the entered age is within the desired range
    if (adjustedAmount < transaction.amount * 90 / 100 || adjustedAmount > transaction.amount * 110 / 100) {
      setAdjustedAmountError(true)
    } else {
      setAdjustedAmountError(false)
    }
  };

  const updateWingWeiLuyStatus = async (transaction, status) => {

    let params = {
      transactionId: transaction.id,
      status: status,
      amount: adjustedAmount,
    }

    if (!stringIsNullOrEmpty(receiptFile)) {
      params["receiptFile"] = receiptFile;
    }

    let formBody = createMultiPartFormBody(params);

    _dispatch(setBusy());


    fetch(ApiUrl._API_UPDATE_WING_WEILUY_TRANSACTION_STATUS, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        getUserTransaction(activeTab);
        setWingWeiluyApprovalModal(false);
      });
  }

  const logoDrop = (acceptedFiles, isDesktop) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];

      setReceiptFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
          setIsNewReceiptImage(true);
          setReceiptImage(reader.result);
        
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <div>
      <h1 className="page-header">{t("TRANSACTION_ENQUIRY") + (stringIsNullOrEmpty(props.username) ? "" : " - " + props.username)}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <Modal isOpen={withdrawalApprovalModal} toggle={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }} centered={true}>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }}>{t("PLEASE_SELECT_AUDIT_BANK_ACCOUNT")}</ModalHeader>
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("AUDIT_BANK")}</b></label>
                            <div className="col-md-7">
                              <Select name="auditBankId" options={auditBankOption}
                                placeholder={(auditBankOption.filter(option => option.value == auditBankId)[0] !== undefined) ? (auditBankOption.filter(option => option.value == auditBankId)[0].label) : ""}
                                value={auditBankOption.filter(option => option.value == auditBankId)}
                                onChange={(e) => {
                                  unregister('auditBankId');
                                  setAuditBankId(e.value);
                                }} />
                              {errors.auditBankId && <div className="invalid-feedback">{t(errors.auditBankId.message)}</div>}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("AUDIT_BANK_ACCOUNT")}</b></label>
                            <div className="col-md-7">
                              <Select name="auditBankAccountId" options={bankAccountOption}
                                placeholder={(bankAccountOption.filter(option => option.value == auditBankAccountId)[0] !== undefined) ? (bankAccountOption.filter(option => option.value == auditBankAccountId)[0].label) : ""}
                                value={bankAccountOption.filter(option => option.value == auditBankAccountId)}
                                onChange={(e) => {
                                  unregister('auditBankAccountId');
                                  setAuditBankAccountId(e.value);
                                }} />
                              {errors.auditBankAccountId && <div className="invalid-feedback">{t(errors.auditBankAccountId.message)}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button type="submit" className="btn btn-primary">{t("CONFIRM")}</button>{' '}
                      <button type="button" className="btn btn-danger" onClick={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }}>{t("CANCEL")}</button>
                    </ModalFooter>
                  </form>
                </Modal>
                {wingWeiluyApprovalModal &&
                  <div className="row">
                    <Modal isOpen={wingWeiluyApprovalModal} toggle={() => { setWingWeiluyApprovalModal(!wingWeiluyApprovalModal); setAdjustedAmount(0); setAdjustedAmountError(false); setTransaction() }} centered={true}>
                      <ModalHeader toggle={() => { setWingWeiluyApprovalModal(!wingWeiluyApprovalModal); setAdjustedAmount(0); setAdjustedAmountError(false); setTransaction() }}>{t("PLEASE_ENTER_ADJUSTED_AMOUNT")}</ModalHeader>
                        <ModalBody>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("REQUEST_AMOUNT")}</b></label>
                                <div className="col-md-7">
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={transaction.amount}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("ADJUSTED_AMOUNT")}</b></label>
                                <div className="col-md-7">
                                  <input
                                    className="form-control"
                                    type="number"
                                    id="adjustedAmount"
                                    name="adjustedAmount"
                                  onChange={handleAdjustedAmountChange}
                                />
                                {adjustedAmount == 0 || adjustedAmountError && <div className="invalid-feedback">{'Adjusted amount must be a number between 10% ± of ' + transaction.amount}</div>}
                                </div>
                              </div>
                          </div>
                          {transaction.transactionTypeId == TransactionType._WITHDRAWAL &&
                            <div className="col-lg-12">
                              <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("RECEIPT")}</b></label>
                                <div className="col-md-7">
                                  <Dropzone
                                    accept={"image/*"}
                                    onDrop={(acceptedFiles) =>
                                      logoDrop(acceptedFiles, true)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section>
                                        <div
                                          className="dropzone"
                                          style={{
                                            minHeight: "200px",
                                            textAlign: "center",
                                          }}
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <h4 style={{ color: "grey" }}>
                                            {t(
                                              "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                            )}
                                          </h4>
                                          {!stringIsNullOrEmpty(receiptImage) && (
                                            <aside className="thumbsContainer">
                                              <div className="thumb">
                                                <div className="thumbInner">
                                                  <img
                                                    src={receiptImage}
                                                    className="dropzone-img"
                                                  />
                                                </div>
                                              </div>
                                            </aside>
                                          )}
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                  <br />
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setReceiptImage("");
                                      setReceiptFile();
                                    }}
                                  >
                                    {t("REMOVE_IMAGE")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          }
                          </div>
                        </ModalBody>
                      <ModalFooter>
                        <button type="button" className="btn btn-primary" disabled={adjustedAmount == 0 || adjustedAmountError} onClick={() => { updateWingWeiLuyStatus(transaction, TransactionStatus._APPROVED ) }}>{t("CONFIRM")}</button>{' '}
                        <button type="button" className="btn btn-danger" onClick={() => { setWingWeiluyApprovalModal(!wingWeiluyApprovalModal); setAdjustedAmount(0); setAdjustedAmountError(false); setTransaction() }}>{t("CANCEL")}</button>
                        </ModalFooter>
                    </Modal>
                    {/*Transaction Modal*/}
                    <Modal isOpen={transactionModal} toggle={() => { setTransactionModal(!transactionModal) }} size="lg" centered={true}>
                      <ModalBody>
                        {transactionComponent}
                      </ModalBody>
                    </Modal>
                  </div>
                }
                {/*Transaction Modal*/}
                <Modal isOpen={transactionModal} toggle={() => { setTransactionModal(!transactionModal) }} size="lg" centered={true}>
                  <ModalBody>
                    {transactionComponent}
                  </ModalBody>
                </Modal>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group" style={{ display: "flex" }}>
                    <div className="input-group" style={{ width: "100%", flexFlow: "nowrap" }}>
                      <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
                      <div className="input-group-append" style={{ width: "100%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          alwaysShowCalendars={true}
                          timePicker={true}
                          timePickerSeconds={true}
                          onApply={handleEvent}
                          ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'This Year': [moment().startOf('year'), moment().endOf('year')]
                          }}>
                          <input type="text" className="form-control" value={calendarStartDate + " - " + calendarEndDate} />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-eye"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedStatus}
                        options={_STATUS_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedStatus(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-sliders-h"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedTransaction}
                        options={_TRANSACTION_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedTransaction(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-sliders-h"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedDepositMethod}
                        options={_DEPOSIT_METHODS}
                        isMulti
                        onChange={(e) => {
                          setSelectedDepositMethod(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-exchange-alt"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedChannel}
                        options={bankingChannelOption}
                        isMulti
                        onChange={(e) => {
                          setSelectedChannel(e)
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fab fa-youtube"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedPanel}
                        options={_PANEL_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedPanel(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {stringIsNullOrEmpty(props.username) && <div className="col-lg-3">
                  <div className="input-group member-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-user"></i></span>
                    </div>
                    <AsyncPaginate
                      placeholder={label}
                      debounceTimeout={250}
                      loadOptions={getMemberList}
                      additional={{
                        page: 1,
                      }}
                      value={username}
                      onChange={(e) => {
                        if (!stringIsNullOrEmpty(e.value)) {
                          sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                        } else {
                          sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                        }
                        setLabel(e.label);
                        setUsername(e.label);
                        console.log(e)
                      }}
                    />
                  </div>
                </div>}
                <div className="col-lg-2">
                  <button type="button" onClick={() => { getUserTransaction(); }} className="btn btn-primary">{t("SEARCH")}</button>
                </div>
              </div>
              {showTransButton && <div className="row m-t-5">
                <div className="col-lg-12">
                  <button type="button" className="btn btn-warning" onClick={() => { setTransactionModal(true); setTransactionType(_DEPOSIT) }}>{t("DEPOSIT")}</button>
                  <button type="button" style={{ marginLeft: "5px" }} className="btn btn-purple" onClick={() => { setTransactionModal(true); setTransactionType(_WITHDRAWAL) }}>{t("WITHDRAWAL")}</button>

                </div>
              </div>}
              <hr />
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={"transaction-page-tab " + classnames({ active: activeTab === _NORMAL_TRANSACTION_TAB })}
                    onClick={() => { toggleTab(_NORMAL_TRANSACTION_TAB); getUserTransaction(_NORMAL_TRANSACTION_TAB); }}>
                    <span>{t("NORMAL_TRANSACTION")}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={"transaction-page-tab " + classnames({ active: activeTab === _GATEWAY_TRANSACTION_TAB })}
                    onClick={() => { toggleTab(_GATEWAY_TRANSACTION_TAB); getUserTransaction(_GATEWAY_TRANSACTION_TAB); }}>
                    <span>{t("GATEWAY_TRANSACTION")}</span>
                  </NavLink>
                </NavItem>
                {role2IsAllowed && <NavItem className="clear-cache">
                  <NavLink
                    onClick={() => window.open(WebUrl._URL_TRANSACTION_QUEUE)}>
                    <span>{t("OPEN_PLAYER_QUEUE")}</span>
                  </NavLink>
                </NavItem>}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={_NORMAL_TRANSACTION_TAB}>
                  <>
                  </>
                </TabPane>
                <TabPane tabId={_GATEWAY_TRANSACTION_TAB}>
                  <>
                  </>
                </TabPane>
              </TabContent>
              {!stringIsNullOrEmpty(props.username) && 
                <div>
                  <Nav>
                    <NavItem>
                      <NavLink
                        className={"transaction-journey-page-tab "}
                        onClick={() => { exportToExcel() }}>
                        <span>{t("MEMBER_ACCOUNT_BALANCE_JOURNEY")}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <CSVLink
                    id="csvBtn"
                    className="btn btn-primary btn-lg"
                    data={exportDatas2}
                    headers={_exportHeaders2}
                    filename={
                      props.username + "_account_balance_journey" +
                      ".csv"
                    }
                    target="_blank"
                    hidden
                  >
                    <i className="fas fa-file-csv"></i>
                  </CSVLink>
                </div>
              }
              <ReactTable fetchUrl={transactionApiUrl} columns={_tableColumns} renderFooter={true} />
              {isViewerOpen && (
                <ImageViewer
                  src={receiptImages}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                />
              )}
            </PanelBody>
          </div>
        </div>

      </Panel>
    </div>
  )
}

export default TransactionEnquiry;