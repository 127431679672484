import React, { useState, useEffect, useRef } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	navigateTo,
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../util/Util";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionStatus,
	LanguageKey,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { showMessage, showToast, setBusy } from "../../redux/AppAction";
import AudioRecorder from "../../util/AudioRecorder";
import FileUpload from "../../util/FileUpload";
import { Display } from "../../util/ChatUtil";
import "./ChatRoom.css";
import { map, debounce, find, filter, upperCase } from "lodash";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import queryString from "query-string";
import ImageViewer from "react-simple-image-viewer";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author : -
/// </summary>
const ChatRoom = (props) => {
	let _history = useHistory();
	const { control } = useForm();
	const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
	var { isLoggedIn } = useSelector((state) => state.authState);
	const [chatList, setChatList] = useState([]);
	const [roomChat, setRoomChat] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const currentPageRef = useRef(currentPage);
	const [totalPages, setTotalPages] = useState(1);
	const totalPagesRef = useRef(totalPages);
	const pageSize = 15; // Set your desired page size here

	const [submitting, setSubmitting] = useState(false);
	const [chatLoading, setChatLoading] = useState(false);
	const [bottomDone, setBottomDone] = useState(true);
	const bottomDoneRef = useRef(bottomDone);
	const [chatCount, setChatCount] = useState(0);
	const chatCountRef = useRef(chatCount);
	const _dispatch = useDispatch();

	const [selectedSenderID, setSelectedSenderID] = useState("");
	const selectedSenderIDRef = useRef(selectedSenderID);
	const [currentSenderId, setCurrentSenderId] = useState("");
	var intervalId = null;
	const roomIntervalRef = useRef(intervalId);

	const [messages, setMessages] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const textareaRef = useRef(null);
	const chatListBoxRef = useRef(null);
	const chatListBoxRefScroll = useRef(null);
	const [inputHeight, setInputHeight] = useState("1px");

	const [isRecording, setIsRecording] = useState(false);
	const isRecordingRef = useRef(isRecording);
	const [selectedSenderData, setSelectedSenderData] = useState([]);
	const [transactionData, setTransactionData] = useState([]);
	const [promotionData, setPromotionData] = useState([]);
	const [rolloverData, setRolloverData] = useState([]);
	const [bonusRolloverData, setBonusRolloverData] = useState([]);
	const [memberDetail, setMemberDetail] = useState();
	const [textareaValue, setTextareaValue] = useState("");
	const [membersWithUnread, setMembersWithUnread] = useState([]);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState(0);
	const [chatImg, setChatImg] = useState([]);
	const [templateList, setTemplateList] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState("");

	const handleInput = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "1px"; // Reset height
			textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
			if (textarea.scrollHeight < textarea.clientHeight) {
				textarea.style.height = "1px"; // Ensure it can shrink back to one row
			}

			setTextareaValue(textarea.value.trim());
		}
	};

	const handleSelectChange = (selectedOption) => {
		if (selectedOption.value === "open_new_tab") {
			const targetUrl = `${WebUrl._URL_MANAGE_CUSTOM_CHAT_TEMPLATE}`;

			// Open the URL in a new tab
			window.open(targetUrl, "_blank");
			return;
		}

		setSelectedTemplate(selectedOption.value);
		setTextareaValue(selectedOption.value.trim());
		setTimeout(handleInput, 0);
	};

	useEffect(() => {
		handleInput();
	}, [textareaValue]);

	useEffect(() => {
		getSelectedChatsTemplate(selectedTemplate);
	}, [selectedTemplate]);

	const calculateLocalTime = (utcDateTime) => {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		const utcDate = new Date(utcDateTime); // Parse UTC date time
		const offset = utcDate.getTimezoneOffset(); // Get offset in minutes

		const adjustedTime = new Date(utcDate.getTime() - offset * 60 * 1000); // Subtract the offset

		// Extract components of the adjusted local time
		const day = String(adjustedTime.getDate()).padStart(2, "0"); // Get day with leading zero
		const monthIndex = adjustedTime.getMonth(); // Get month index
		const month = months[monthIndex]; // Get month abbreviation
		const year = adjustedTime.getFullYear(); // Get full year
		const hours = String(adjustedTime.getHours()).padStart(2, "0"); // Get hours with leading zero
		const minutes = String(adjustedTime.getMinutes()).padStart(2, "0"); // Get minutes with leading zero

		// Construct the formatted local time string
		const localTimeString = `${day} ${month}, ${hours}:${minutes}`;

		return localTimeString;
	};

	const sendMessage = async (e) => {
		e.preventDefault();

		if (selectedSenderID === "") {
			return false;
		}

		const content = textareaValue;
		if (content == "") {
			return false;
		}
		setSubmitting(true);

		const messageData = {
			ReceiverID: selectedSenderID,
			MessageType: "Text",
			Content: content,
		};

		try {
			textareaRef.current.value = "";
			const newMessage = {
				name: "Sending...",
				sender: "User",
				datetime: "",
				content: content,
				messageType: "Text",
			};
			setRoomChat([...roomChat, newMessage]);
			const response = await fetch(ApiUrl._API_SEND_CHAT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(messageData),
			});

			if (response.ok) {
				textareaRef.current.value = "";
				handleInput();
				setInputValue("");
				setSelectedTemplate("");
				getChat();
			} else {
				// Handle errors, such as displaying a message to the user
				alert("Failed to send message.");
			}
		} catch (error) {
			console.error("Error sending message:", error);
			alert("An error occurred while sending the message.");
		}

		setSubmitting(false);
	};

	useEffect(() => {
		chatCountRef.current = chatCount;
	}, [chatCount]);

	useEffect(() => {
		bottomDoneRef.current = bottomDone;
	}, [bottomDone]);

	const scrollToBottom = () => {
		if (bottomDoneRef.current) return;
		const chatListBox = chatListBoxRefScroll.current;
		if (chatListBox) {
			chatListBox.scrollTop = chatListBox.scrollHeight;
		}
		setBottomDone(true);
		handleInput();
	};

	const getChat = async () => {
		if (
			!selectedSenderIDRef ||
			stringIsNullOrEmpty(selectedSenderIDRef.current)
		)
			return false;

		getAllChatsTemplate();

		let apiUrl = `${ApiUrl._API_GET_CHAT_ROOM}`;
		apiUrl += "?id=" + selectedSenderIDRef.current;

		await fetch(apiUrl, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					if (
						chatCount > 0 &&
						chatCountRef.current ===
						responseJson[ApiKey._API_DATA_KEY].length &&
						currentSenderId === selectedSenderID
					)
						return;
					setCurrentSenderId(selectedSenderID);
					setChatCount(responseJson[ApiKey._API_DATA_KEY].length);
					setRoomChat([...responseJson[ApiKey._API_DATA_KEY]]);
					setTimeout(function () {
						scrollToBottom();
					}, 100);
				}
			});
	};

	const getAllChats = async (pageNumber) => {
		let apiUrl = `${ApiUrl._API_GET_CHAT_LIST}`;
		apiUrl += `?languageId=1&pageNumber=${currentPageRef.current}&pageSize=${pageSize}`;

		try {
			const response = await fetch(apiUrl, { method: "GET" });
			const responseJson = await response.json();
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				getMembersWithUnreadNotification();
				setChatList(responseJson[ApiKey._API_DATA_KEY].chat);
				setTotalPages(responseJson[ApiKey._API_DATA_KEY].pages);
				totalPagesRef.current = responseJson[ApiKey._API_DATA_KEY].pages;
			}
		} catch (error) {
			console.error("Error fetching chat list:", error);
		}
	};

	const getMembersWithUnreadNotification = () => {
		// I'm sorry I had to do it this way because doing this in EF Core required major change to DB tables ChatRooms and ChatMessages - Chris
		try {
			if (!window.location.pathname.match(/login/)) {
				fetch(ApiUrl._API_GET_MEMBER_WITH_UNREAD_NOTIFICATIONS, {
					method: ApiKey._API_GET,
					headers: {
						Accept: ApiKey._API_APPLICATION_JSON,
						"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
					},
				})
					.then((response) => response.json())
					.then((responseJson) => {
						if (responseJson[ApiKey._API_SUCCESS_KEY]) {
							setMembersWithUnread(responseJson[ApiKey._API_DATA_KEY]);
						}
					});
			}
		} catch (error) {
			console.error("Error fetching chat list unread status:", error);
		}
	};

	useEffect(() => {
		//_dispatch(showToast({ Title: "Testing Title", Body: "Testing Body "+Math.random() } ));
		selectedSenderIDRef.current = selectedSenderID;
		setBottomDone(false);

		if (roomIntervalRef.current !== null) {
			clearInterval(roomIntervalRef.current);
		}

		roomIntervalRef.current = setInterval(getChat, 5000);
		getChat();

		if (!stringIsNullOrEmpty(selectedSenderID)) {
			getDataBySelectedSenderId(selectedSenderID);
		}
		return () => clearInterval(roomIntervalRef.current);
	}, [selectedSenderID]);

	var intervalId2 = null;
	useEffect(() => {
		getAllChats();
		// Get the query parameter from the URL and load the corresponding chat
		const queryParams = new URLSearchParams(window.location.search);
		const chatId = queryParams.get("chatId") || "";
		setSelectedSenderID(chatId);

		if (intervalId2 !== null) {
			clearInterval(intervalId2);
		}
		intervalId2 = setInterval(getAllChats, 5000);

		const contactsPanel = document.getElementById("contacts-panel");
		contactsPanel.addEventListener("scroll", handleScroll);

		return () => {
			contactsPanel.removeEventListener("scroll", handleScroll);
			clearInterval(intervalId2);
		};
	}, []);

	const handleAudioData = async (sent) => {
		if (!sent) {
			const newMessage = {
				name: "Sending...",
				sender: "User",
				datetime: "",
				content: "Uploading file...",
				messageType: "Text",
			};
			setRoomChat([...roomChat, newMessage]);
		} else {
			handleInput();
			setBottomDone(false);
			getChat();
		}
	};

	const handleAudioRecording = (status) => {
		if (status) {
			setIsRecording(true);
			isRecordingRef.current = true;
		} else {
			setIsRecording(false);
			isRecordingRef.current = false;
		}
	};

	const handleFileUpload = async (sent) => {
		if (!sent) {
			const newMessage = {
				name: "Sending...",
				sender: "User",
				datetime: "",
				content: "Uploading file...",
				messageType: "Text",
			};
			setRoomChat([...roomChat, newMessage]);
		} else {
			setBottomDone(false);
			getChat();
		}
	};

	const handleScroll = (event) => {
		const contactsPanel = event.target;
		if (
			contactsPanel.scrollTop + contactsPanel.clientHeight >=
			contactsPanel.scrollHeight
		) {
			// Reached the bottom of the scroll container
			if (currentPageRef.current < totalPagesRef.current) {
				setCurrentPage((prevPage) => prevPage + 1); // Load next page
				currentPageRef.current = currentPageRef.current + 1;

				getAllChats();

				if (intervalId2 !== null) {
					clearInterval(intervalId2);
				}
				intervalId2 = setInterval(getAllChats, 5000);
			}
		}
	};

	async function getDataBySelectedSenderId(selectedSenderID) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_DOWNLINE_BY_REFERRER2}?memberId=${selectedSenderID}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			const senderData = responseJson[ApiKey._API_DATA_KEY]["data"];

			let member = {
				memberId: selectedSenderID,
				username: senderData[0]?.["username"],
			};
			setMemberDetail(member);
			setSelectedSenderData(senderData);

			var responseJson2 = await ApiEngine.get(
				`${ApiUrl._API_GET_LATEST_MEMBER_DATA}?username=${senderData[0]?.["username"]}`
			);

			if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
				setTransactionData(responseJson2[ApiKey._API_DATA_KEY]["transaction"]);
				setPromotionData(responseJson2[ApiKey._API_DATA_KEY]["promotion"]);
				setRolloverData(responseJson2[ApiKey._API_DATA_KEY]["rollover"]);
				setBonusRolloverData(
					responseJson2[ApiKey._API_DATA_KEY]["bonusRollover"]
				);
			}
		}
	}

	const renderApprovalStatusBadge = (status, approvalStatusString) => {
		switch (status) {
			case TransactionStatus._APPROVED:
				return <span className="text-approved font-bold">Approved</span>;
			case TransactionStatus._REJECTED:
				return <span className="text-rejected font-bold">Rejected</span>;
			default:
				return (
					<span className="text-pending font-bold">{approvalStatusString}</span>
				);
		}
	};

	// Helper function to render JSON as HTML
	const renderJsonData = (data) => {
		return (
			<div>
				<label>
					<b>
						<u>Details</u>
					</b>
				</label>
				<ol className="pl-3">
					{Object.entries(data).map(([key, value]) => (
						<li key={key}>
							<b>{formatKey(key)} :</b> {formatValue(key, value)}
						</li>
					))}
				</ol>
			</div>
		);
	};

	// Helper function to format keys
	const formatKey = (key) => {
		return key
			.replace(/([A-Z])/g, " $1")
			.replace(/^./, (str) => str.toUpperCase());
	};

	// Helper function to format values, especially dates
	const formatValue = (key, value) => {
		if (key === "StartDate" || key === "EndDate") {
			return moment(value).format("YYYY-MM-DD HH:mm:ss");
		}
		if (typeof value === "boolean") {
			return value ? "True" : "False";
		}
		return value;
	};

	const updateSelectedChat = (senderID) => {
		const params = new URLSearchParams(window.location.search);
		params.set("chatId", senderID);
		_history.replace({ search: params.toString() });
	};

	const handleSelectChat = (senderID) => {
		if (senderID != selectedSenderID) {
			setSelectedSenderID(senderID);
			updateSelectedChat(senderID);
		} else setSelectedSenderID("");
	};

	const handleClick = () => {
		// Create the URL with query parameters
		const stateString = queryString.stringify(memberDetail);
		const targetUrl = `${WebUrl._URL_VIEW_ACCOUNT}?${stateString}`;

		// Open the URL in a new tab
		window.open(targetUrl, "_blank");
	};

	const closeImageViewer = () => {
		setIsViewerOpen(false);
	};

	const getAllChatsTemplate = async () => {
		try {
			fetch(ApiUrl._API_GET_CUSTOM_CHAT_TEMPLATE + "?all=true", {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						let data = responseJson[ApiKey._API_DATA_KEY];
						const tempList = [];
						data.map((data) => {
							tempList.push({
								label: data.title,
								value: data.code,
							});
						});

						tempList.push({
							label: "(Add New Template)",
							value: "open_new_tab",
						});

						setTemplateList(tempList);
					}
				});
		} catch (error) {
			console.error("Error fetching chat template:", error);
		}
	};

	async function getSelectedChatsTemplate(value) {
		if (!stringIsNullOrEmpty(value)) {
			var apiUrl = ApiUrl._API_GET_CUSTOM_CHAT_TEMPLATE_CODE;
			apiUrl += "?code=" + value;
			await fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setTextareaValue(responseJson[ApiKey._API_DATA_KEY]["content"]);
					}
				});
		}
	}

	const customStyles = {
		menu: (provided) => ({
			...provided,
			bottom: "100%",
			top: "auto",
			right: "-10px",
			marginBottom: "8px",
			width: "auto",
		}),
		menuList: (provided) => ({
			...provided,
			whiteSpace: "normal",
			wordBreak: "break-word",
			overflowWrap: "break-word",
		}),
		option: (provided) => ({
			...provided,
			fontSize: "12px",
			whiteSpace: "normal",
			wordBreak: "break-word",
			overflowWrap: "break-word",
		}),
		control: (provided) => ({
			...provided,
			position: "absolute",
			top: "-10px",
			right: "-10px",
			minWidth: "150px",
			width: "auto",
			fontSize: "12px",
			backgroundColor: "unset",
			borderRadius: "unset",
			borderStyle: "unset",
			borderWidth: 0,
			borderColor: "unset",
			boxShadow: "none",
		}),
	};

	return (
		<div className="chatroom-container">
			<div id="contacts-panel" className="contacts-panel">
				{/* <PerfectScrollbar> */}
				{chatList.map((chat) => (
					<div
						key={chat.senderID}
						className={`contact${chat.senderID === selectedSenderID ? " selected" : ""
							}`}
						onClick={() => {
							handleSelectChat(chat.senderID);
						}}
					>
						<div className="d-flex align-items-center justify-content-between m-b-5">
							<div className="livechat-name">{chat.senderName}</div>
							<div className="livechat-time">
								{chat.dateTime !== "" && calculateLocalTime(chat.dateTime)}
								{membersWithUnread &&
									membersWithUnread.find(
										(member) => member.senderID === chat.senderID
									) && (
										<div className="unread-indicator">
											{
												membersWithUnread.find(
													(member) => member.senderID === chat.senderID
												).count
											}
										</div>
									)}
							</div>
						</div>
						<div className="livechat-content">{Display(chat)}</div>
					</div>
				))}
				{/* </PerfectScrollbar> */}
			</div>
			{selectedSenderID !== "" && (
				<>
					<div className="chat-interface">
						<div ref={chatListBoxRef} id="chatroom" className="messages">
							<PerfectScrollbar
								containerRef={(ref) => {
									chatListBoxRefScroll.current = ref;
								}}
							>
								<div className="t3-livechat-list-box">
									{roomChat &&
										roomChat.map((chat, index) => {
											return (
												<div
													className={
														chat.sender === "User"
															? "t3-livechat-message-row out"
															: "t3-livechat-message-row in"
													}
													key={index}
												>
													<div
														className={
															chat.sender === "User"
																? "t3-livechat-message-container out"
																: "t3-livechat-message-container in"
														}
													>
														<span className=""></span>
														<div className="t3-livechat-message-wrapper">
															<div
																className={
																	chat.sender === "User"
																		? "t3-live-chat-message-tail out"
																		: "t3-live-chat-message-tail in"
																}
															></div>
															{chat.sender === "Member" && (
																<div className="t3-live-chat-message-profile-wrapper">
																	<div className="t3-live-chat-message-profile">
																		<div className="t3-live-chat-message-alphabet">
																			{/* <span>👤</span> */}
																			<svg
																				width="36px"
																				height="36px"
																				viewBox="0 0 512 512"
																			>
																				<path
																					fillRule="evenodd"
																					clipRule="evenodd"
																					d="M311.164,282.519c8.449-8.289,11.385-29.254,19.12-36.81  c10.955-10.706,22.845-23.883,26.209-40.334c4.514-21.86-4.653-24.635-4.653-32.189c0-15.827-0.144-41.822-5.086-58.706  c-0.284-22.271-3.938-31.957-11.602-40.299c-7.23-7.806-24.992-5.962-34.268-11.101c-14.355-7.967-26.153-10.993-41.212-11.386  v-0.089c-0.446,0-0.876,0.053-1.322,0.053c-0.735,0-1.434-0.053-2.204-0.053l0.037,0.142c-36.684,1.379-75.713,24.509-89.123,61.712  c-4.976,13.785-3.08,43.899-3.08,59.726c0,7.555-9.166,10.329-4.653,32.189c3.382,16.451,15.254,29.628,26.209,40.334  c7.736,7.556,10.671,28.521,19.102,36.81c1.844,12.21,2.059,8.719,1.988,19.891c-0.019,3.777,0.107,9.632-5.925,15.396  c-11.405,10.921-37.597,34.607-59.082,43.827c-28.32,12.156-74.37,31.6-84.985,37.077S18,419.029,18,428.481  c0,9.472,0,44.33,0,44.33h237.287h5.427H498c0,0,0-34.858,0-44.33c0-9.452-27.999-24.295-38.637-29.772  c-10.633-5.478-56.643-24.921-84.966-37.077c-20.912-8.969-46.298-31.67-58.151-42.931c-4.009-3.795-6.838-7.771-6.875-17.438  c-0.069-11.242,0.218-6.946,2.007-18.744"
																				/>
																			</svg>
																		</div>
																	</div>
																</div>
															)}

															<div className="t3-livechat-message">
																<div className="t3-livechat-content">
																	<div className="d-flex align-items-center justify-content-between m-b-5">
																		<div
																			className={
																				chat.sender === "User"
																					? "t3-livechat-username out"
																					: "t3-livechat-username in"
																			}
																		>
																			<span>{chat.name}</span>
																		</div>
																		<span className="t3-livechat-text-time">
																			<span>
																				{chat.dateTime !== "" &&
																					calculateLocalTime(chat.dateTime)}
																			</span>
																		</span>
																	</div>
																	<div
																		className="t3-livechat-text-content"
																		onClick={() => {
																			if (chat.messageType == "Image") {
																				setIsViewerOpen(true);
																				let imgArray = [];
																				imgArray.push(chat.content);
																				setChatImg(imgArray);
																			}
																		}}
																	>
																		{Display(chat)}
																	</div>
																</div>
															</div>
														</div>
														<div className="t3-livechat-message-end"></div>
													</div>
												</div>
											);
										})}
								</div>
							</PerfectScrollbar>
						</div>
						{isViewerOpen && (
							<div className="receipt-img">
								<ImageViewer
									src={chatImg}
									currentIndex={currentImage}
									onClose={closeImageViewer}
									closeOnClickOutside={true}
								/>
							</div>
						)}
						<div className="t3-livechat-input-container">
							<div className="t3-livechat-input-wrapper">
								<div className="t3-livechat-input">
									<span className=""></span>
									<span className="">
										<div className="t3-livechat-input-full">
											{!isRecordingRef.current && (
												<div className="t3-livechat-input-left">
													<FileUpload
														url={ApiUrl._API_SEND_FILE}
														onFileUpload={handleFileUpload}
														memberId={selectedSenderID}
													/>
												</div>
											)}
											<div
												className={`t3-livechat-input-right ${textareaValue.length < 1
														? " send-voice"
														: " send-message"
													}`}
											>
												{!isRecordingRef.current && (
													<div className="t3-livechat-input-textarea-container">
														<div className="t3-livechat-input-textarea-wrapper">
															<div className="select-template-wrapper">
																<Controller
																	control={control}
																	name="template"
																	render={({ onChange, value }) => (
																		<Select
																			id="chat-template-selection"
																			classNamePrefix="select-template"
																			styles={customStyles}
																			isSearchable={false}
																			components={{
																				IndicatorSeparator: () => null,
																			}}
																			options={templateList}
																			placeholder={
																				selectedTemplate === ""
																					? t("SELECT_TEMPLATE")
																					: templateList.filter(
																						(option) =>
																							option.value ===
																							selectedTemplate
																					)[0].label
																			}
																			value={selectedTemplate}
																			onChange={(e) => {
																				handleSelectChange(e);
																				onChange(e.value);
																			}}
																		/>
																	)}
																/>
															</div>
															<textarea
																ref={textareaRef}
																className="t3-livechat-input-textarea"
																placeholder="Type a message"
																// style={{ height: "36px" }}
																onInput={handleInput}
																value={textareaValue}
																onChange={(e) =>
																	setTextareaValue(e.target.value)
																}
															></textarea>
														</div>
													</div>
												)}

												<div
													className={
														isRecordingRef.current
															? "t3-livechat-input-voice recording"
															: "t3-livechat-input-voice"
													}
												>
													<AudioRecorder
														url={ApiUrl._API_SEND_VOICE}
														onAudioRecorded={handleAudioData}
														onAudioRecording={handleAudioRecording}
														memberId={selectedSenderID}
													/>
												</div>
												{!isRecordingRef.current && (
													<div
														className={"t3-livechat-input-send"}
														onClick={sendMessage}
														disabled={submitting}
													>
														<svg
															width="40"
															height="40"
															viewBox="0 0 30 30"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<circle cx="15" cy="15" r="15" fill="#2A7145" />
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M21.21 10.0606C21.255 9.76437 21.1287 9.4675 20.8844 9.29312C20.64 9.11937 20.3181 9.0975 20.0525 9.23687C17.9269 10.3531 10.4912 14.2569 8.20748 15.4556C7.9256 15.6031 7.75935 15.9044 7.78373 16.2212C7.8081 16.5381 8.0181 16.8106 8.31935 16.9137C9.20873 17.2181 10.2956 17.5912 12 18.1756L18.875 11.75L13.3112 18.625C15.1287 19.2481 17.9706 20.2225 18.6837 20.4669C18.9069 20.5438 19.1531 20.5169 19.355 20.395C19.5569 20.2725 19.6937 20.0669 19.7294 19.8331L21.21 10.0606F"
																fill="white"
															/>
														</svg>
													</div>
												)}
											</div>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="chat-info">
						<PerfectScrollbar options={{ suppressScrollX: true }}>
							{selectedSenderData && selectedSenderData.length > 0 && (
								<div>
									<section className="user-info">
										<table className="table table-bordered table-hover">
											<tbody>
												<tr>
													<td>Username</td>
													<td>
														<a className="btn btn-default" onClick={handleClick}>
															{selectedSenderData[0]["username"]}
														</a>
													</td>
												</tr>
												<tr>
													<td>Full Name</td>
													<td>{selectedSenderData[0]["userFullName"]}</td>
												</tr>
												<tr>
													<td>Phone</td>
													<td>{selectedSenderData[0]["phoneNumber"]}</td>
												</tr>
												<tr>
													<td>Date Registered</td>
													<td>{selectedSenderData[0]["createdTime"]}</td>
												</tr>
												<tr>
													<td>Total Downline</td>
													<td>123123</td>
												</tr>
												<tr>
													<td>Balance</td>
													<td>{selectedSenderData[0]["walletBalance"]}</td>
												</tr>
												<tr>
													<td>Bonus Balance</td>
													<td>{selectedSenderData[0]["bonusBalance"]}</td>
												</tr>
											</tbody>
										</table>
									</section>

									<label className="m-t-10">
										<h5>Latest Transactions</h5>
									</label>
									<table className="table table-bordered table-hover">
										<thead>
											<tr>
												<th>Deposit</th>
												<th>Withdraw</th>
												<th>Turnover</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["depositTotal"]),
														3
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["withdrawalTotal"]),
														3
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["betValidTotal"]),
														3
													)}
												</td>
											</tr>
										</tbody>
									</table>

									<table className="table table-bordered table-hover">
										<thead>
											<tr>
												<th>Bonus</th>
												<th>Commission</th>
												<th>Adjustment</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["bonusTotal"]),
														3
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["commissionTotal"]),
														3
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(selectedSenderData[0]["adjustmentTotal"]),
														3
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}


							{transactionData && transactionData.length > 0 && (
								<>
									<label className="m-t-10">
										<h5>Last 5 Transactions</h5>
									</label>
									<PerfectScrollbar
										options={{ suppressScrollY: true }}
										style={{ height: "fit-content" }}
									>
										<table className="table table-bordered table-hover">
											<thead>
												<tr>
													<th>ID</th>
													<th>Time</th>
													<th>Type</th>
													<th>Amount</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody>
												{map(transactionData, (data, index) => {
													return (
														<tr key={index}>
															<td>{data["id"]}</td>
															<td>
																{moment(data["transactionDateTime"]).format(
																	"YYYY-MM-DD HH:mm:ss"
																)}
															</td>
															<td>{data["transactionType"]}</td>
															<td>
																{numberWithCurrencyFormat(
																	parseFloat(data["amount"]),
																	3
																)}
															</td>
															<td>
																{renderApprovalStatusBadge(
																	data["approvalStatus"],
																	data["approvalStatusString"]
																)}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</PerfectScrollbar>
								</>
							)}

							{promotionData && promotionData.length > 0 && (
								<>
									<label className="m-t-10">
										<h5>Promotions</h5>
									</label>
									<PerfectScrollbar
										options={{ suppressScrollY: true }}
										style={{ height: "fit-content" }}
									>
										<table className="table table-bordered table-hover">
											<thead>
												<tr>
													<th>Promotion</th>
													<th>Time</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												{map(promotionData, (data, index) => {
													return (
														<tr key={index}>
															<td>
																<label>{data["title"]}</label>
																<br />

																{stringIsNullOrEmpty(data["remark"])
																	? ""
																	: renderJsonData(JSON.parse(data["remark"]))}
															</td>
															<td>
																{moment(data["transactionDateTime"]).format(
																	"YYYY-MM-DD HH:mm:ss"
																)}
															</td>
															<td>
																{numberWithCurrencyFormat(
																	parseFloat(data["amount"]),
																	3
																)}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</PerfectScrollbar>
								</>
							)}

							{rolloverData && rolloverData.length > 0 && (
								<>
									<label className="m-t-10">
										<h5>Rollover</h5>
									</label>
									<PerfectScrollbar
										options={{ suppressScrollY: true }}
										style={{ height: "fit-content" }}
									>
										<table className="table table-bordered table-hover">
											<thead>
												<tr>
													<th>Time</th>
													<th>Amount</th>
													<th>Progress</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody>
												{map(rolloverData, (data, index) => {
													var statusHtml = "";
													statusHtml =
														data["isLatestRecord"] == 1 ? (
															<span
																className={
																	data["rolloverProgress"] ==
																		data["rolloverTarget"]
																		? "text-approved font-bold clickable"
																		: "text-rejected font-bold clickable-span"
																}
															>
																{data["status"]}
															</span>
														) : (
															<span className="text-pending font-bold">
																{data["status"]}
															</span>
														);
													return (
														<tr key={index}>
															<td>{data["date"]}</td>
															<td>
																{numberWithCurrencyFormat(
																	parseFloat(data["amount"]),
																	3
																)}
															</td>
															<td>
																{data["isLatestRecord"] == 1
																	? data["rolloverProgress"]
																	: data["rolloverTarget"]}{" "}
																/ {data["rolloverTarget"]}
															</td>
															<td>{statusHtml}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</PerfectScrollbar>
								</>
							)}

							{bonusRolloverData && bonusRolloverData.length > 0 && (
								<>
									<label className="m-t-10">
										<h5>Bonus Rollover</h5>
									</label>
									<PerfectScrollbar
										options={{ suppressScrollY: true }}
										style={{ height: "fit-content" }}
									>
										<table className="table table-bordered table-hover">
											<thead>
												<tr>
													<th>Time</th>
													<th>Amount</th>
													<th>Progress</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody>
												{map(bonusRolloverData, (data, index) => {
													var statusHtml = "";
													statusHtml =
														data["isLatestRecord"] == 1 ? (
															<span
																className={
																	data["rolloverTarget"] -
																		data["rolloverBalance"] ==
																		data["rolloverTarget"]
																		? "text-approved font-bold clickable"
																		: "text-rejected font-bold clickable-span"
																}
															>
																{data["status"]}
															</span>
														) : (
															<span className="text-pending font-bold">
																{data["status"]}
															</span>
														);
													return (
														<tr key={index}>
															<td>{data["createdTime"]}</td>
															<td>
																{numberWithCurrencyFormat(
																	parseFloat(data["bonusAmount"]),
																	3
																)}
															</td>
															<td>
																{(
																	data["rolloverTarget"] -
																	data["rolloverBalance"]
																).toFixed(3)}
																/ {data["rolloverTarget"]}
															</td>
															<td>{statusHtml}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</PerfectScrollbar>
								</>
							)}
						</PerfectScrollbar>
					</div>
				</>
			)}
		</div>
	);
};

export default ChatRoom;
