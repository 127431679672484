import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton.jsx";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
} from "../../util/Constant.js";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util.js";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import { fetch } from "whatwg-fetch";

const ManageCustomChatTemplate = (props) => {
	const _DETAIL_TAB = "1";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();
	const { register, unregister, handleSubmit, errors, setValue } = useForm();
	const [content, setContent] = useState([]);
	const [title, setTitle] = useState("");
	const [code, setCode] = useState("");
	const [status, setStatus] = useState(true);

	useEffect(() => {
		setTitle("");
		init();
	}, []);

	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	async function init() {
		if (_location.state) {
			let templateCode = _location.state.code;
			var apiUrl = ApiUrl._API_GET_CUSTOM_CHAT_TEMPLATE_CODE;
			apiUrl += "?code=" + templateCode;
			await fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
						setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
						setCode(responseJson[ApiKey._API_DATA_KEY]["code"]);
						setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
					}
				});
		}
	}

	const submitForm = async (data, e) => {
		_dispatch(setBusy());

		let params = {
			title: String(data.title),
			content: String(data.content),
			status: Boolean(data.status),
		};

		if (!stringIsNullOrEmpty(code)) {
			params["code"] = code;
		}

		fetch(ApiUrl._API_CREATE_OR_UPDATE_CUSTOM_CHAT_TEMPLATE, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_APPLICATION_JSON,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: JSON.stringify(params),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					_history.goBack();
				}
			});
	};

	return (
		<div>
			<h1 className="page-header">
				<div className="page-title">
					<NavigationButton history={_history} />
					{_location.state ? t("EDIT") : t("ADD")} {t("CHAT_CUSTOM_TEMPLATE")}
				</div>
				<div className="page-actions"></div>
			</h1>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-2 col-form-label">
														<b>{t("TITLE")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder="Title"
															name="title"
															defaultValue={title}
															ref={register({
																required: "REQUIRED_FIELD",
															})}
														/>
														{errors.title && (
															<div className="invalid-feedback">
																{t(errors.title.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-2 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-7">
														<textarea
															className="form-control"
															name="content"
															placeholder="@Username, @Mobile, @WalletBrand, @WalletLink, @TeamLink will be auto replaced by customer."
															rows="5"
															style={{ marginTop: "10px" }}
															defaultValue={content}
															ref={register({
																required: "REQUIRED_FIELD",
															})}
															onChange={(e) => setContent(e.target.value)}
														></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-2 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group">
													<button
														type="submit"
														className="btn btn-primary pull-right"
													>
														{t("SAVE")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default ManageCustomChatTemplate;
