import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
// import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ChatTemplate = (props) => {
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [contentData, setContentData] = useState([]);

  let _tableColumns = [
    {
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "Default",
      accessor: "defaultContent",
      disableSortBy: true,
    },
    {
      Header: "My Setting",
      accessor: "myContent",
      disableSortBy: true,
      Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._CONTENT_SETTING_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
                <a
                  className="btn btn-default"
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_CHAT_SYSTEM_TEMPLATE,
                      row.original
                    );
                  }}
                >
                  {t("EDIT")}
                </a>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_CHAT_TEMPLATE + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setContentData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div style={{padding: "10px 0 0"}}>
      <h1 className="page-header">
        <div className="page-title">
          {t("Chat System Template")}
        </div>
        <div className="page-actions"></div>
      </h1>
      <Panel>
        <ReactTable data={contentData} columns={_tableColumns} />
      </Panel>
    </div>
  );
};

export default ChatTemplate;
